import axios from "axios";
const $API = axios.create({
    baseURL: "https://cg-main-server.com",
    // baseURL: "http://localhost:4000/",

    headers: {
        "Content-type": "application/json",
    }

});
$API.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token');
    config.headers!.Authorization = `Bearer ${token}`;;

    return config;
});

$API.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('userRole')
            window.location.href = "/auth";
        }
        return Promise.reject(error);
    }
);
export default $API;

