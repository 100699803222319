import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ButtonToolbar, Dropdown } from "rsuite";
import Navbar, { NavbarTab } from "../../components/navbar/Navbar";
import Lists from '../lists/list';

interface IAppHubParams {
  tab: NavbarTab;
  component: JSX.Element
}

export default (params: IAppHubParams) => {
  let { tab, component } = params;

  return (
    <div className="app">
      <Navbar tab={tab} />
      {component}
    </div>
  );
};
