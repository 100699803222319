import React, { useState } from "react";
import { TOffer, TPartner } from "types/offers-temporary";
import { Input, Modal, Button } from "rsuite";
import "./_offer-crud.scss";

interface OfferCrudModalProps {
  offer?: TOffer;
  offers: TOffer[];
  partners: TPartner[];
  onClose: () => void;
}

export const OfferCrudModal: React.FC<OfferCrudModalProps> = ({ offer, offers, partners, onClose }) => {
  const [partnerIndex, setPartnerIndex] = useState<number | null>(
    offer
      ? partners.findIndex((p: TPartner) => p.partnerId === offer.partnerId)
      : null
  );
  const [geo, setGeo] = useState<string | null>(offer?.geo || null);
  const [link, setLink] = useState<string | null>(offer?.link || null);
  const [idUnlocked, setIdUnlocked] = useState(false);
  const [nameUnlocked, setNameUnlocked] = useState(false);

  return (
    <Modal open onClose={onClose}>
      <Modal.Header>
        <Modal.Title>{offer ? 'Edit Offer' : 'New Offer'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Input value={offer?.name || ''} onChange={value => {}} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
