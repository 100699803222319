import React, { useState, useEffect, useRef } from 'react';
import { Droppable, DropResult } from 'react-beautiful-dnd';
import { Radio, RadioGroup } from 'rsuite';
import { PanelService } from '../../../../network/panel/network';
import './_all-offers-list.scss';
import { GroupOffersBy, IOffersAdminResponse, TOffer, TPartner } from 'types/offers-temporary';
import CountriesSubList from './countries-list/countries-sub-list';
import PartnersSubList from './partners-list/partners-sub-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faGlobe, faHandshake } from '@fortawesome/free-solid-svg-icons';
import type { ISectionsList } from '@bprotsyk/aso-core/lib/offers/list';

interface AllOffersListProps {
  droppableId: string;
  sectionsList: ISectionsList;
  onDragStart: (offer: TOffer) => void;
  onDragEnd: (result: DropResult) => void;
  getOfferHiddenState: (offerId: number) => boolean;
}

const AllOffersList: React.FC<AllOffersListProps> = ({ 
  droppableId, 
  sectionsList, 
  onDragStart, 
  onDragEnd,
  getOfferHiddenState
}) => {
  const [offers, setOffers] = useState<IOffersAdminResponse | null>(null);
  const [groupingType, setGroupingType] = useState<'partners' | 'countries'>('partners');
  const [searchQuery, setSearchQuery] = useState('');
  const [allExpanded, setAllExpanded] = useState(false);
  
  // Separate filtered data for each view
  const [filteredPartnersData, setFilteredPartnersData] = useState<Record<string, TOffer[]>>({});
  const [filteredCountriesData, setFilteredCountriesData] = useState<Record<string, TOffer[]>>({});
  
  // Cache for offers data
  const offersCache = useRef<Record<string, IOffersAdminResponse>>({
    partners: {
      partners: [],
      byPartners: {},
      byCountries: {}
    },
    countries: {
      partners: [],
      byPartners: {},
      byCountries: {}
    }
  });

  // Helper function to find which section an offer is in
  const findOfferSection = (offerId: number): number | null => {
    for (const section of sectionsList.content) {
      if (Object.values(section.offerStatesOrdered).some(states => 
        states.some(state => state.offerId === offerId)
      )) {
        return section.sectionId;
      }
    }
    return null;
  };

  // Filter offers based on search query
  const filterOffers = (offersData: IOffersAdminResponse): IOffersAdminResponse => {
    if (!searchQuery) return offersData;

    const query = searchQuery;
    const filteredByPartners: Record<string, TOffer[]> = {};
    const filteredByCountries: Record<string, TOffer[]> = {};
    const partnersMap = Object.fromEntries(offersData.partners.map((p: TPartner) => [p.partnerId, p]));
    
    // Get all offers from both groupings to ensure we don't miss any
    const allOffers = [
      ...Object.values(offersData.byPartners || {}).flat(),
      ...Object.values(offersData.byCountries || {}).flat()
    ].filter((offer, index, self) => 
      // Remove duplicates based on offer ID
      index === self.findIndex((o) => o.id === offer.id)
    );

    allOffers.forEach((offer: TOffer) => {
      const partner = partnersMap[offer.partnerId];
      const matchesSearch = 
        offer.name?.includes(query) ||
        offer.caption?.includes(query) ||
        // Only country code search should be case-insensitive
        offer.geo?.toLowerCase().includes(query.toLowerCase()) ||
        partner?.name?.includes(query);

      if (matchesSearch) {
        // Add to partners grouping
        if (!filteredByPartners[offer.partnerId]) {
          filteredByPartners[offer.partnerId] = [];
        }
        if (!filteredByPartners[offer.partnerId].some(o => o.id === offer.id)) {
          filteredByPartners[offer.partnerId].push(offer);
        }

        // Add to countries grouping
        if (!filteredByCountries[offer.geo]) {
          filteredByCountries[offer.geo] = [];
        }
        if (!filteredByCountries[offer.geo].some(o => o.id === offer.id)) {
          filteredByCountries[offer.geo].push(offer);
        }
      }
    });

    return {
      ...offersData,
      byPartners: filteredByPartners,
      byCountries: filteredByCountries,
      partners: offersData.partners
    };
  };

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        // Use cached data immediately if available
        const cachedData = offersCache.current[groupingType];
        if (cachedData && (cachedData.partners.length > 0 || Object.keys(cachedData.byPartners).length > 0)) {
          setOffers(cachedData);
          const filtered = filterOffers(cachedData);
          if (groupingType === 'partners') {
            setFilteredPartnersData(filtered.byPartners || {});
          } else {
            setFilteredCountriesData(filtered.byCountries || {});
          }
        }

        // Fetch new data
        const response = await PanelService.getAllOffers(
          groupingType === 'partners' ? GroupOffersBy.PARTNERS : GroupOffersBy.COUNTRIES,
          false,
          true
        );

        // Update cache and state
        offersCache.current[groupingType] = response;
        setOffers(response);
        
        const filtered = filterOffers(response);
        if (groupingType === 'partners') {
          setFilteredPartnersData(filtered.byPartners || {});
        } else {
          setFilteredCountriesData(filtered.byCountries || {});
        }
      } catch (error) {
        console.error('Error fetching offers:', error);
      }
    };

    fetchOffers();
  }, [groupingType]);

  // Update filtered data when search query changes
  useEffect(() => {
    if (offers) {
      const filtered = filterOffers(offers);
      setFilteredPartnersData(filtered.byPartners || {});
      setFilteredCountriesData(filtered.byCountries || {});
    }
  }, [searchQuery]);

  // Auto-expand all when searching
  useEffect(() => {
    setAllExpanded(!!searchQuery);
  }, [searchQuery]);

  if (!offers?.partners) return <div>Loading...</div>;

  // Create a map of partners for faster lookup
  const partnersMap = new Map(offers.partners.map(p => [String(p.partnerId), p]));

  return (
    <div className="all-offers-container">
      <div className="all-offers-settings-container">
        <div className="search-container">
          <input
            type="text"
            className="search-input"
            placeholder="Пошук за назвою, партнером, країною..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {searchQuery && (
            <button 
              className="clear-button"
              onClick={() => setSearchQuery('')}
              title="Очистити пошук"
            >
              ✕
            </button>
          )}
        </div>
        <div className="controls-row">
          <RadioGroup
            name="grouping"
            value={groupingType}
            onChange={value => setGroupingType(value as 'partners' | 'countries')}
            inline
          >
            <Radio value="partners">
              <FontAwesomeIcon icon={faHandshake} /> За партнерками
            </Radio>
            <Radio value="countries">
              <FontAwesomeIcon icon={faGlobe} /> За країнами
            </Radio>
          </RadioGroup>
          <button
            className="expand-all-button"
            onClick={() => setAllExpanded(!allExpanded)}
            title={allExpanded ? "Згорнути всі" : "Розгорнути всі"}
            data-expanded={allExpanded}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
      </div>

      <Droppable droppableId={droppableId} isDropDisabled={true}>
        {(provided) => (
          <div
            className="all-offers-list"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <div className="partners-view" style={{ display: groupingType === 'partners' ? 'block' : 'none' }}>
              {Object.entries(filteredPartnersData).map(([partnerId, partnerOffers]) => {
                const partner = partnersMap.get(partnerId);
                if (!partner) {
                  console.log('Missing partner for ID:', partnerId);
                  return null;
                }
                return (
                  <PartnersSubList
                    key={partnerId}
                    partnerId={partnerId}
                    partner={partner}
                    offers={partnerOffers as TOffer[]}
                    findOfferSection={findOfferSection}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    isExpanded={allExpanded}
                    getOfferHiddenState={getOfferHiddenState}
                  />
                );
              })}
            </div>
            <div className="countries-view" style={{ display: groupingType === 'countries' ? 'block' : 'none' }}>
              {Object.entries(filteredCountriesData).map(([countryCode, countryOffers]) => (
                <CountriesSubList
                  key={countryCode}
                  countryCode={countryCode}
                  offers={countryOffers as TOffer[]}
                  partners={Object.fromEntries(offers.partners.map(p => [p.partnerId, p]))}
                  findOfferSection={findOfferSection}
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                  isExpanded={allExpanded}
                  getOfferHiddenState={getOfferHiddenState}
                />
              ))}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default AllOffersList; 