import React, { useState, useRef, useEffect } from 'react';
import ReactCountryFlag from "react-country-flag";
import { TOffer, TPartner } from 'types/offers-temporary';
import './_countries-list.scss';
import OfferDragGhost from '../item/offer-drag-ghost';
import { DropResult } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

interface CountriesSubListProps {
  countryCode: string;
  offers: TOffer[];
  partners: Record<string, TPartner>;
  findOfferSection: (offerId: number) => number | null;
  onDragStart: (offer: TOffer) => void;
  onDragEnd: (result: DropResult) => void;
  isExpanded?: boolean;
  getOfferHiddenState: (offerId: number) => boolean;
}

const CountriesSubList: React.FC<CountriesSubListProps> = ({ 
  countryCode, 
  offers, 
  partners, 
  findOfferSection, 
  onDragStart, 
  onDragEnd,
  isExpanded: forceExpanded,
  getOfferHiddenState
}) => {
  const [isLocalExpanded, setIsLocalExpanded] = useState(false);
  const [currentOffer, setCurrentOffer] = useState<TOffer | null>(null);
  const dragImageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (forceExpanded !== undefined) {
      setIsLocalExpanded(forceExpanded);
    }
  }, [forceExpanded]);

  const handleDragStart = (e: React.DragEvent, offer: TOffer) => {
    const sectionId = findOfferSection(offer.id);
    if (sectionId !== null) {
      e.preventDefault();
      return;
    }

    setCurrentOffer(offer);
    onDragStart?.(offer);
    
    e.dataTransfer.setData('text/plain', JSON.stringify({
      ...offer,
      id: offer.id,
      name: offer.name,
      partnerId: offer.partnerId,
      graphicFolder: offer.graphicFolder,
      geo: countryCode
    }));

    if (dragImageRef.current) {
      dragImageRef.current.style.display = 'flex';
      dragImageRef.current.style.opacity = '1';
      dragImageRef.current.style.transition = 'none';
      e.dataTransfer.setDragImage(dragImageRef.current, 100, 25);
      
      setTimeout(() => {
        if (dragImageRef.current) {
          dragImageRef.current.style.display = 'none';
        }
      }, 0);
    }
  };

  const handleDragEnd = () => {
    if (dragImageRef.current) {
      dragImageRef.current.style.display = 'flex';
      dragImageRef.current.style.transition = 'opacity 0.2s ease-out';
      dragImageRef.current.style.opacity = '0';
      
      setTimeout(() => {
        if (dragImageRef.current) {
          dragImageRef.current.style.display = 'none';
          dragImageRef.current.style.opacity = '1';
          dragImageRef.current.style.transition = 'none';
        }
        setCurrentOffer(null);
      }, 200);
    }
    onDragEnd({
      destination: null,
      source: { index: 0, droppableId: '' },
      draggableId: '',
      reason: 'DROP',
      mode: 'FLUID',
      type: 'DEFAULT',
      combine: null
    });
  };

  // Group offers by partnerId
  const groupedOffers = offers.reduce((acc, offer) => {
    const group = offer.partnerId || 'Other';
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(offer);
    return acc;
  }, {} as Record<string, TOffer[]>);

  // Create flattened list of offers in the order they appear in the UI
  const flattenedOffers = Object.entries(groupedOffers)
    .flatMap(([_, groupOffers]) => groupOffers);

  const getSectionTitle = (id: number) => {
    switch (id) {
      case 1:
        return '🎰 ПЕРЕВІР СВОЮ ВДАЧУ! 🎰';
      case 2:
        return '🥇 СТАВ І ВИГРАВАЙ! 🥇';
      default:
        return `Section ${id}`;
    }
  };

  if (offers.length === 0) return null;

  return (
    <div className="all-country-section countries-section">
      <div className="all-country-title countries-title" 
        onClick={() => setIsLocalExpanded(!isLocalExpanded)}
        data-expanded={isLocalExpanded}
      >
        <div className="country-header countries-header">
          <ReactCountryFlag 
            style={{
              fontSize: '1rem',
              lineHeight: '1rem',
            }} 
            className="country-flag countries-flag" 
            countryCode={countryCode} 
          />
          <span className="country-name countries-name">{countryCode}</span>
        </div>
        <div className="header-indicators">
          {flattenedOffers.map((offer) => {
            const sectionId = findOfferSection(offer.id);
            const isHidden = getOfferHiddenState(offer.id);
            return (
              <div 
                key={offer.id}
                className={`offer-indicator ${sectionId !== null ? 'offer-indicator--in-section' : ''} ${isHidden ? 'offer-indicator--hidden' : ''}`}
              />
            );
          })}
        </div>
        <span className="section-expand-icon">
          <FontAwesomeIcon icon={faCaretDown} />
        </span>
      </div>
      {isLocalExpanded && (
        <div className="country-offers-container countries-list-container">
          {Object.entries(groupedOffers).map(([partnerId, groupOffers]) => (
            <React.Fragment key={partnerId}>
              <div className="countries-subgroup-header">
                {partners[partnerId]?.name || 'Other'}
              </div>
              {groupOffers.map((offer) => {
                const sectionId = findOfferSection(offer.id);
                const isHidden = getOfferHiddenState(offer.id);
                const showIndicator = sectionId !== null || isHidden;
                return (
                  <div
                    key={offer.id}
                    className={`country-offer-item countries-offer-item ${sectionId !== null ? 'countries-offer-item--disabled' : ''}`}
                    draggable={sectionId === null}
                    onDragStart={(e) => handleDragStart(e, offer)}
                    onDragEnd={handleDragEnd}
                  >
                    <div className="offer-logo countries-offer-logo">
                      <img className="offer-thumbnail-bg countries-offer-thumbnail-bg" 
                        src={`https://cg-data-server.com/static/offers/images/${offer.graphicFolder}/bg.png`} />
                      <img className="offer-thumbnail-fg countries-offer-thumbnail-fg" 
                        src={`https://cg-data-server.com/static/offers/images/${offer.graphicFolder}/logo.png`} />
                    </div>
                    <div className="offer-details countries-offer-details">
                      <div className="offer-name countries-offer-name">{offer.caption}</div>
                      {sectionId !== null && (
                        <div className="countries-offer-section">{getSectionTitle(sectionId)}</div>
                      )}
                    </div>
                    {showIndicator && (
                      <div className={`offer-item-indicator ${sectionId !== null ? 'offer-item-indicator--in-section' : ''} ${isHidden ? 'offer-item-indicator--hidden' : ''}`} />
                    )}
                  </div>
                );
              })}
            </React.Fragment>
          ))}
        </div>
      )}
      
      <OfferDragGhost 
        offer={currentOffer} 
        dragImageRef={dragImageRef}
        partnerName={currentOffer ? partners[currentOffer.partnerId]?.name : undefined}
        countryCode={countryCode}
      />
    </div>
  );
};

export default CountriesSubList; 