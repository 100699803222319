import {
  Button,
  Form,
  Message,
  Modal,
  useToaster,
  Stack,
  Input,
  Dropdown,
  Badge,
} from "rsuite";
import "./_add-domains.scss";
import { HTMLInputTypeAttribute, useEffect, useState } from "react";
import { PanelService } from "../../../network/panel/network";
import RemindRoundIcon from "@rsuite/icons/RemindRound";
import React from "react";
import { DomainTarget, getDomainTargetByIp } from "@bprotsyk/aso-core";

interface IProps {
  onClose: () => void;
}
interface IDomainSetupResult {
  succeed: string[];
  failed: string[];
}

const notValidIp = "Невалідний або пустий IP";
const notValidDomain = "Неправильні дані";
const reloadMessage =
  "Додавання в процесі. Домени додадуться, але ви не зможете побачити результат операції!";
const domainPlaceHolder =
  "Вкажіть домени без схеми (просто domain.com), розділені комою, пробілом або новими рядками";

const placement = "topEnd";
const errorMessage = "Виникла проблема при додаванні доменів";
const succeedMessage = "Домени успішно додано";

interface IP {
  IP: string;
  target: DomainTarget | string;
}

export default function AddDomainModal({ onClose }: IProps) {
  const [domains, setDomains] = useState<string[]>([]);
  const [rows, setRows] = useState(5);
  const [ipErr, setIpErr] = useState<boolean | string>(false);
  const [domainErr, setDomainErr] = useState<boolean | string>(false);
  const [toggle, setTogle] = useState<boolean>(false);
  const [result, setResult] = useState<IDomainSetupResult | null>(null);
  const [selectedOption, setSelectedOption] = useState<DomainTarget>(
    DomainTarget.OTHER
  );
  const [buttonDisable, setButtonDisable] = useState<boolean>(true);
  const [ip, setIP] = useState<IP>({
    IP: "",
    target: "other",
  });
  const toaster = useToaster();

  const domainPattern =
    /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)*[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
  const ipPattern =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  const calculateRowsForText = (text: string) => {
    // Заміна всіх переходів на новий рядок та табуляцій на пробіли
    const normalizedText = text.replace(/\s+/g, " ").trim();

    // Якщо текст порожній після нормалізації, повертаємо 1 рядок
    if (!normalizedText) {
      return 1;
    }

    // Максимальна кількість символів в одному рядку
    const charsPerLine = 70;

    // Підрахунок кількості рядків з урахуванням символів переведення рядків (\n)
    const lines = normalizedText.split("\n").reduce((acc, line) => {
      return acc + Math.ceil(line.length / charsPerLine);
    }, 0);

    // Повертаємо кількість рядків або мінімум 1
    setRows(Math.max(lines + 1, 5));
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value: string = e.target.value;
    calculateRowsForText(value);

    // Розділяємо текст на домени, але зберігаємо коми та пробіли
    const domainArr: string[] = value
      .split(/[,\s\n]+/)
      .map((domain) => domain.trim().replace(/\s+/g, ",").replace(/,,+/g, ","));

    // Перевіряємо кожен домен окремо
    if (domainArr.length > 0) {
      const invalidDomains = domainArr.filter(
        (domain) => !domainPattern.test(domain)
      );
      console.log(domainArr.length);

      if (invalidDomains.length > 0 && invalidDomains[0] !== "") {
        setDomainErr(`Некоректні домени: ${invalidDomains.join(", ")}`);
      } else {
        setDomainErr(false);
      }

      setDomains(domainArr);
    }
  };

  useEffect(() => {
    if (!domainErr && !ipErr && ip.IP.length > 5 && ip.target) {
      setButtonDisable(false);
    } else setButtonDisable(true);
  }, [domainErr, ipErr, domainErr, domains, ip]);

  const handleIpChange = (handleIP: string) => {
    if (ip.target === "other") {
      setIP({
        IP: handleIP,
        target: getDomainTargetByIp(handleIP) || "unknown",
      });

      if (!ip.target || !ipPattern.test(handleIP)) {
        setIpErr(notValidIp);
      } else {
        setIpErr(false);
      }
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (toggle) {
        event.preventDefault();
        return reloadMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [toggle]);

  let ipElement = (
    <Input
      required
      className={`input custom_ip_input ${ipErr ? "input_error" : ""}`}
      id="ip"
      placeholder="ip"
      value={ip.target === "other" ? ip.IP : ip.target}
      disabled={ip.target !== "unknown" && ip.target !== "other"}
      onChange={(ip: string) => handleIpChange(ip)}
    />
  );

  const closeModal = () => {
    if (!toggle) {
      onClose();
    }
    if (toggle && result) {
      onClose();
    }
  };

  const sendData = () => {
    if (!domainErr && !ipErr && ip.IP.length > 0) {
      const filterData = domains.filter((domain) => domain !== "");
      const body = { domains: filterData, ip: ip.IP };

      PanelService.addDomain(body).then((res) => {
        const succeed = res?.data.succeed.length;
        const failed = res?.data.failed.length;
        console.log(succeed, failed);
        if (res && succeed && !failed) {
          setResult(res.data);
          setTogle(true);

          setButtonDisable(!buttonDisable);
          toaster.push(message("success", succeedMessage), {
            placement: placement,
            duration: 5000,
          });
        } else {
          toaster.push(message("error", errorMessage), {
            placement: placement,
            duration: 5000,
          });
          onClose();
        }
      });
    }
    if (ip.IP.length <= 0) {
      setIpErr("поле IP  обовязкове");
    }
  };

  const message = (messageType: any, mesageText: string) => {
    return (
      <Message key={new Date().getTime()} showIcon type={messageType} closable>
        <strong>{mesageText}</strong>
      </Message>
    );
  };

  const selectedOptionToUperCase = (text: any) => {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
  };
  const handleSelect = (target: DomainTarget) => {
    setSelectedOption(target);
    const domainTargetToIp: Record<DomainTarget, string> = {
      [DomainTarget.MAIN]: "185.123.53.227",
      [DomainTarget.KEITARO]: "46.246.96.114",
      [DomainTarget.HOSTING]: "46.246.98.201",
      [DomainTarget.UNKNOWN]: "none",
      [DomainTarget.OTHER]: "unknown",
    };
    const selectedTarget = domainTargetToIp[target];

    if (selectedTarget !== "unknown") {
      setIP({
        IP: domainTargetToIp[target],
        target: getDomainTargetByIp(domainTargetToIp[target]),
      });
    } else {
      setIP({
        IP: "",
        target: getDomainTargetByIp(domainTargetToIp[target]),
      });
    }
  };

  return (
    <div className="add_domain_modal">
      <Modal backdrop={"static"} size="sm" open={true} onClose={closeModal}>
        {!toggle && (
          <Modal.Header>
            <Modal.Title>{`Додавання нових доменів`}</Modal.Title>
          </Modal.Header>
        )}
        {result && (
          <Modal.Header>
            <Modal.Title
              style={{ justifyContent: "center" }}
            >{`Додані домени`}</Modal.Title>
          </Modal.Header>
        )}
        {!result && (
          <Modal.Body>
            <div className="container">
              <Stack
                className="content"
                spacing={7}
                direction="column"
                alignItems="stretch"
              >
                <div className="domain_wrap">
                  <div className="text_area_custom_wrap">
                    <textarea
                      rows={5}
                      value={domains}
                      placeholder={domainPlaceHolder}
                      onChange={(e) => handleTextareaChange(e)}
                      className={`ip_input ${domainErr ? "input_error" : ""}`}
                    />
                    {domainErr && (
                      <div className="hover_error">
                        <RemindRoundIcon className="error_icon" />
                        <span className="err_message">{domainErr}</span>
                      </div>
                    )}
                  </div>
                  <div className="input_custom_wrap">
                    {ipElement}
                    {ipErr && (
                      <div className="hover_error">
                        <RemindRoundIcon className="error_icon" />
                        <span className="err_message">{ipErr}</span>
                      </div>
                    )}
                  </div>
                </div>
              </Stack>
            </div>
          </Modal.Body>
        )}
        {result && (
          <div className="result_container">
            {result.succeed.length > 0 && (
              <div className="list_wrap">
                <ul className="result_list">
                  {result.succeed.map((items, index) => (
                    <li key={index} className="list_item">
                      <Badge
                        style={{
                          background: "#34c3ff",
                        }}
                      />
                      <p className="result_text">{items}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {result.failed.length > 0 && (
              <div className="list_wrap">
                <ul className="result_list">
                  {result.failed.map((items, index) => (
                    <li key={index} className="list_item">
                      <Badge
                        style={{
                          background: "#e02012",
                        }}
                      />
                      <p className="result_text">{items}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <Button
              style={{
                padding: " 15px 35px",
                width: "35%",
              }}
              onClick={() => {
                setResult(null);
                onClose();
              }}
              color="green"
              appearance="primary"
            >
              Гаразд
            </Button>
          </div>
        )}
        {!result && (
          <Modal.Footer>
            <div className="add_btn_wrap">
              <Dropdown
                title={selectedOptionToUperCase(selectedOption) || "Ціль"}
              >
                {Object.values(DomainTarget).sort((a, b) => (a === DomainTarget.MAIN ? -1 : b === DomainTarget.MAIN ? 1 : 0)).map((option) => (
                  <Dropdown.Item
                    key={option}
                    onSelect={() => handleSelect(option)}
                  >
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </Dropdown.Item>
                ))}
              </Dropdown>
              <Button
                onClick={() => {
                  sendData();
                  setButtonDisable(!buttonDisable);
                }}
                color="green"
                disabled={buttonDisable}
                appearance="primary"
              >
                Додати домени{" "}
              </Button>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
}
