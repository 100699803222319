import React, { useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  Badge,
  Toggle,
  Input,
  Button,
  TagPicker,
  Modal,
} from "rsuite";
import "./_platforms.scss";
import { EPlatform, IPlatformParams } from "@bprotsyk/aso-core";
import { countryNames, getCountryLabel } from "../../../../constants/countries";
import CloseIcon from "@rsuite/icons/Close";
import initialState from "ui/admin/interface/initial-interface";

interface PlatformsAccordionProps {
  platforms: Record<string, IPlatformParams | null>;
  onPlatformDataChange: (
    updatedPlatforms: Record<string, IPlatformParams>
  ) => void;
  selectedPlatform?: EPlatform | null;
}

type ExtendedPlatformParams = IPlatformParams & {
  newPlatform?: boolean;
};

const PlatformsAccordion: React.FC<PlatformsAccordionProps> = ({
  platforms = {},
  onPlatformDataChange,
  selectedPlatform,
}) => {
  const allPlatforms = Object?.values(EPlatform);
  const [active, setActive] = useState<string>(selectedPlatform || "@");
  const [keitaroDataCompany, setKeitaroDataCompany] = useState<boolean>(true);
  const [platformStates, setPlatformStates] = useState<
    Record<string, ExtendedPlatformParams>
  >(() => {
    const initialStates: Record<string, ExtendedPlatformParams> = {};
    Object.keys(platforms || {}).forEach((key) => {
      initialStates[key] = {
        enabled: platforms[key]?.enabled || false,
        geo: platforms[key]?.geo || ["UZ","AZ","BY","MD","PL","CZ","RU","UA","KZ"],
        adjustParams: {
          appId: platforms[key]?.adjustParams?.appId || "",
          eventIds: {
            lead: platforms[key]?.adjustParams?.eventIds?.lead || "",
            hold: platforms[key]?.adjustParams?.eventIds?.hold || "",
            sale: platforms[key]?.adjustParams?.eventIds?.sale || "",
            rejected: platforms[key]?.adjustParams?.eventIds?.rejected || "",
          },
        },
        metricaParams: {
          appId: platforms[key]?.metricaParams?.appId || 0,
          postApiKey: platforms[key]?.metricaParams?.postApiKey || "",
        },
        proxied: platforms[key]?.proxied || false,
        keitaroData: platforms[key]?.keitaroData || false,
        customCampaignData: platforms[key]?.customCampaignData || undefined,
        newPlatform: true,
      };
    });
    return initialStates;
  });

  const [newPlatforms, setNewPlatforms] = useState<IPlatformParams[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toggleValue, setToggleValue] = useState<boolean | undefined>(
    undefined
  );

  const handleEditPlatformChange = (
    platformKey: string,
    updatedParams: Partial<IPlatformParams>
  ) => {
    console.log(
      "Editing platform:",
      platformKey,
      "with params:",
      updatedParams
    );
    setPlatformStates((prevStates) => {
      const updatedState = {
        ...prevStates,
        [platformKey]: {
          ...prevStates[platformKey],
          ...updatedParams,
        },
      };
      console.log("Updated platformStates:", updatedState);
      onPlatformDataChange(updatedState);
      return updatedState;
    });
  };

  const handleCreatePlatformChange = (
    index: number,
    updatedParams: Partial<IPlatformParams>
  ) => {
    console.log("Creating new platform with:", updatedParams);
    setNewPlatforms((prevPlatforms) => {
      const updatedPlatforms = [...prevPlatforms];
      updatedPlatforms[index] = {
        ...updatedPlatforms[index],
        ...updatedParams,
        enabled:
          updatedParams.enabled ?? updatedPlatforms[index]?.enabled ?? false,
        geo: updatedParams.geo ?? updatedPlatforms[index]?.geo ?? [],
        adjustParams: {
          ...updatedPlatforms[index]?.adjustParams,
          ...updatedParams.adjustParams,
          appId:
            updatedParams.adjustParams?.appId ??
            updatedPlatforms[index]?.adjustParams?.appId ??
            "",
          eventIds: {
            lead:
              updatedParams.adjustParams?.eventIds?.lead ??
              updatedPlatforms[index]?.adjustParams?.eventIds?.lead ??
              "",
            hold:
              updatedParams.adjustParams?.eventIds?.hold ??
              updatedPlatforms[index]?.adjustParams?.eventIds?.hold ??
              "",
            sale:
              updatedParams.adjustParams?.eventIds?.sale ??
              updatedPlatforms[index]?.adjustParams?.eventIds?.sale ??
              "",
            rejected:
              updatedParams.adjustParams?.eventIds?.rejected ??
              updatedPlatforms[index]?.adjustParams?.eventIds?.rejected ??
              "",
          },
        },
        metricaParams: {
          ...updatedPlatforms[index]?.metricaParams,
          ...updatedParams.metricaParams,
          appId:
            updatedParams.metricaParams?.appId ??
            updatedPlatforms[index]?.metricaParams?.appId ??
            0,
          postApiKey:
            updatedParams.metricaParams?.postApiKey ??
            updatedPlatforms[index]?.metricaParams?.postApiKey ??
            "",
        },
        proxied:
          updatedParams.proxied ?? updatedPlatforms[index]?.proxied ?? false,
        keitaroData:
          updatedParams.keitaroData ??
          updatedPlatforms[index]?.keitaroData ??
          false,
      };
      console.log("Updated newPlatforms:", updatedPlatforms);
      handleSaveNewPlatforms(updatedPlatforms);
      return updatedPlatforms;
    });
  };

  const handleAddNewPlatform = () => {
    setPlatformStates((prevStates) => ({
      ...prevStates,
      [active]: {
        adjustParams: {
          appId: "",
          eventIds: { lead: "", hold: "", sale: "", rejected: "" },
        },
        metricaParams: { appId: 0, postApiKey: "" },
        geo: ["UZ","AZ","BY","MD","PL","CZ","RU","UA","KZ"],
        enabled: false,
        proxied: false,
        keitaroData: false,
        customCampaignData: undefined,
        newPlatform: true,
      },
    }));
  };

  const handleSaveNewPlatforms = (
    platformsToSave: IPlatformParams[] = newPlatforms
  ) => {
    const updatedStates = { ...platformStates };
    platformsToSave.forEach((platform, index) => {
      const key = active;
      updatedStates[key] = platform;
    });
    setPlatformStates(updatedStates);
    setNewPlatforms([]);
    onPlatformDataChange(updatedStates);
    console.log("Created Platforms:", updatedStates);
  };

  useEffect(() => {
    if (
      active === EPlatform.GENERAL &&
      typeof platformStates[EPlatform.GENERAL]?.keitaroData === "boolean" &&
      !platformStates[EPlatform.GENERAL]?.keitaroData
    ) {
      setPlatformStates((prevStates) => ({
        ...prevStates,
        [EPlatform.GENERAL]: {
          ...prevStates[EPlatform.GENERAL],
          keitaroData: true,
        },
      }));
    }
  }, [platformStates, active]);

  const currentPlatform = platformStates[active] ||
    newPlatforms[0] || {
      adjustParams: {
        appId: "",
        eventIds: { lead: "", hold: "", sale: "", rejected: "" },
      },
      metricaParams: { appId: 0, postApiKey: "" },
      geo: [],
      enabled: false,
      proxied: false,
      keitaroData: active === EPlatform.GENERAL ? true : false,
    };

  const availableCountries = Object.keys(countryNames).map((code) => ({
    label: getCountryLabel(code),
    value: code,
  }));

  const handleToggleChange = (value: boolean) => {
    console.log(value);
    if (value) {
      setToggleValue(value);
      setIsModalOpen(true);
    } else {
      handleEditPlatformChange(active, {
        keitaroData: false,
      });
    }
  };

 

  const handleConfirm = () => {
    if (toggleValue !== undefined) {
      handleEditPlatformChange(active, {
        keitaroData: toggleValue,
      });
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {platformStates && (
        <div className="platforms-accordion">
          <p className="section-title">Платформи</p>
          <Navbar appearance="subtle">
            <Nav
              activeKey={active}
              onSelect={(eventKey) => setActive(eventKey)}
            >
              {allPlatforms
                ?.filter((platform) => platform)
                ?.map((platform) => {
                  const platformExists =
                    platforms &&
                    Object.keys(platforms).length > 0 &&
                    platforms[platform];
                  const params = platforms?.[platform];
                  return (
                    <Badge
                      color={params?.enabled ? "green" : "yellow"}
                      className="custom_badge"
                    >
                      <Nav.Item
                        active={active === platform}
                        className={`custom_tabs ${
                          active === platform ? "active" : ""
                        }`}
                        key={platform}
                        eventKey={platform}
                        style={{
                          backgroundColor: platformExists
                            ? "rgb(0, 123, 255)"
                            : "rgb(106 111 118)",
                          color: "#fff",
                        }}
                      >
                        <div className="platform_container">
                          {platform}
                          {params?.proxied && (
                            <img
                              className="proxy_budge"
                              src="/icons/cloudflare-active.png"
                            />
                          )}
                          {params?.keitaroData && <p className="keitaro">K</p>}
                        </div>
                      </Nav.Item>
                    </Badge>
                  );
                })}
            </Nav>
          </Navbar>
          <div className="platform-details">
            {active && platformStates[active] ? (
              <div className="wrap_platform-details">
                <div className="heder-platform-details">
                  <div
                    className="proxy_container"
                    onClick={() =>
                      handleEditPlatformChange(active, {
                        proxied: !currentPlatform.proxied,
                      })
                    }
                  >
                    {!currentPlatform.proxied && (
                      <img
                        className="proxy_togle"
                        src="/icons/cloudflare.png"
                      />
                    )}
                    {currentPlatform.proxied && (
                      <img
                        className="proxy_togle"
                        src="/icons/cloudflare-active.png"
                      />
                    )}
                  </div>
                  {platformStates[active]?.newPlatform && (
                    <div className="new-platform-toggle">
                      <label className="toggle-label">Окрема кампанія</label>
                      <Toggle
                        checked={
                          active === EPlatform.GENERAL
                            ? true
                            : Boolean(platformStates[active]?.keitaroData)
                        }
                        disabled={active === EPlatform.GENERAL}
                        onChange={(e) => {
                          handleToggleChange(e);
                        }}
                      />
                    </div>
                  )}
                  <Toggle
                    className="status-toggle"
                    checked={currentPlatform.enabled}
                    onChange={(value) =>
                      handleEditPlatformChange(active, { enabled: value })
                    }
                    checkedChildren="Увімк"
                    unCheckedChildren="Вимк"
                    size="md"
                  />
                </div>
                <div className="platform-params">
                  <label>Країни</label>

                  <TagPicker
                    className="custom_country_picker"
                    searchable={true}
                    data={availableCountries.map((country) => ({
                      ...country,
                      label: (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <img
                            src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/${country?.value?.toLowerCase()}.svg`}
                            style={{ width: 20, height: 15 }}
                          />
                          <span>{country.label}</span>
                        </div>
                      ),
                    }))}
                    value={currentPlatform?.geo}
                    onChange={(value) =>
                      handleEditPlatformChange(active, { geo: value })
                    }
                    cleanable
                    renderValue={(value, items) => (
                      <div className="tag-container">
                        {items.map((item: any) => (
                          <div key={item.value} className="tag-item">
                            <img
                              src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/${item.value.toLowerCase()}.svg`}
                              className="tag-flag"
                            />
                            <span className="tag-code">{item.value}</span>
                            <CloseIcon
                              onClick={() => {
                                const newValue = value.filter(
                                  (v: any) => v !== item.value
                                );
                                handleEditPlatformChange(active, {
                                  geo: newValue,
                                });
                              }}
                              className="tag-remove"
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  />
                  <p className="section-header">Adjust</p>
                  <label>Adjust App ID</label>
                  <Input
                    placeholder="Adjust App ID"
                    value={currentPlatform.adjustParams?.appId || ""}
                    onChange={(value) =>
                      handleEditPlatformChange(active, {
                        adjustParams: {
                          ...currentPlatform.adjustParams!,
                          appId: value,
                        },
                      })
                    }
                    style={{ width: "100%" }}
                  />
                  <label>Lead Event ID</label>
                  <Input
                    placeholder="Lead Event ID"
                    value={currentPlatform.adjustParams?.eventIds.lead || ""}
                    onChange={(value) =>
                      handleEditPlatformChange(active, {
                        adjustParams: {
                          ...currentPlatform.adjustParams!,
                          eventIds: {
                            ...currentPlatform.adjustParams!.eventIds,
                            lead: value,
                          },
                        },
                      })
                    }
                    style={{ width: "100%" }}
                  />
                  <label>Hold Event ID</label>
                  <Input
                    placeholder="Hold Event ID"
                    value={currentPlatform.adjustParams?.eventIds.hold || ""}
                    onChange={(value) =>
                      handleEditPlatformChange(active, {
                        adjustParams: {
                          ...currentPlatform.adjustParams!,
                          eventIds: {
                            ...currentPlatform.adjustParams!.eventIds,
                            hold: value,
                          },
                        },
                      })
                    }
                    style={{ width: "100%" }}
                  />
                  <label>Sale Event ID</label>
                  <Input
                    placeholder="Sale Event ID"
                    value={currentPlatform.adjustParams?.eventIds.sale || ""}
                    onChange={(value) =>
                      handleEditPlatformChange(active, {
                        adjustParams: {
                          ...currentPlatform.adjustParams!,
                          eventIds: {
                            ...currentPlatform.adjustParams!.eventIds,
                            sale: value,
                          },
                        },
                      })
                    }
                    style={{ width: "100%" }}
                  />
                  <label>Rejected Event ID</label>
                  <Input
                    placeholder="Rejected Event ID"
                    value={
                      currentPlatform?.adjustParams?.eventIds.rejected || ""
                    }
                    onChange={(value) =>
                      handleEditPlatformChange(active, {
                        adjustParams: {
                          ...currentPlatform.adjustParams!,
                          eventIds: {
                            ...currentPlatform.adjustParams!.eventIds,
                            rejected: value,
                          },
                        },
                      })
                    }
                    style={{ width: "100%" }}
                  />
                  <p className="section-header">Metrica</p>
                  <label>Metrica App ID</label>
                  <Input
                    placeholder="Metrica App ID"
                    value={
                      currentPlatform.metricaParams?.appId?.toString() || ""
                    }
                    onChange={(value) =>
                      handleEditPlatformChange(active, {
                        metricaParams: {
                          ...currentPlatform.metricaParams!,
                          appId: parseInt(value) || 0,
                        },
                      })
                    }
                    style={{ width: "100%" }}
                  />
                  <label>Metrica Post API Key</label>
                  <Input
                    placeholder="Metrica Post API Key"
                    value={currentPlatform.metricaParams?.postApiKey || ""}
                    onChange={(value) =>
                      handleEditPlatformChange(active, {
                        metricaParams: {
                          ...currentPlatform.metricaParams!,
                          postApiKey: value,
                        },
                      })
                    }
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            ) : (
              <div className="create-platform-interface">
                {newPlatforms.map((platform, index) => (
                  <div key={index} className="wrap_platform-details">
                    <div className="heder-platform-details">
                      <div
                        className="proxy_container"
                        onClick={() =>
                          handleCreatePlatformChange(index, {
                            proxied: !platform.proxied,
                          })
                        }
                      >
                        {!platform.proxied && (
                          <img
                            className="proxy_togle"
                            src="/icons/cloudflare.png"
                          />
                        )}
                        {platform.proxied && (
                          <img
                            className="proxy_togle"
                            src="/icons/cloudflare-active.png"
                          />
                        )}
                      </div>
                      <Toggle
                        className="status-toggle"
                        checked={platform.enabled}
                        onChange={(value) =>
                          handleCreatePlatformChange(index, { enabled: value })
                        }
                        checkedChildren="Увімк"
                        unCheckedChildren="Вимк"
                        size="md"
                      />
                    </div>
                    <div className="platform-params">
                      <label>Країни</label>

                      <TagPicker
                        className="custom_country_picker"
                        searchable={true}
                        data={availableCountries.map((country) => ({
                          ...country,
                          label: (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              <img
                                src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/${country.value.toLowerCase()}.svg`}
                                style={{ width: 20, height: 15 }}
                              />
                              <span>{country.label}</span>
                            </div>
                          ),
                        }))}
                        value={platform.geo}
                        onChange={(value) =>
                          handleCreatePlatformChange(index, { geo: value })
                        }
                        cleanable
                        renderValue={(value, items) => (
                          <div className="tag-container">
                            {items.map((item: any) => (
                              <div key={item.value} className="tag-item">
                                <img
                                  src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/${item.value.toLowerCase()}.svg`}
                                  className="tag-flag"
                                />
                                <span className="tag-code">{item.value}</span>
                                <CloseIcon
                                  onClick={() => {
                                    const newValue = value.filter(
                                      (v: any) => v !== item.value
                                    );
                                    handleCreatePlatformChange(index, {
                                      geo: newValue,
                                    });
                                  }}
                                  className="tag-remove"
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      />
                      <p className="section-header">Adjust</p>
                      <label>Adjust App ID</label>
                      <Input
                        placeholder="Adjust App ID"
                        value={platform.adjustParams?.appId || ""}
                        onChange={(value) =>
                          handleCreatePlatformChange(index, {
                            adjustParams: {
                              ...platform.adjustParams!,
                              appId: value,
                            },
                          })
                        }
                        style={{ width: "100%" }}
                      />
                      <label>Lead Event ID</label>
                      <Input
                        placeholder="Lead Event ID"
                        value={platform.adjustParams?.eventIds.lead || ""}
                        onChange={(value) =>
                          handleCreatePlatformChange(index, {
                            adjustParams: {
                              ...platform.adjustParams!,
                              eventIds: {
                                ...platform.adjustParams!.eventIds,
                                lead: value,
                              },
                            },
                          })
                        }
                        style={{ width: "100%" }}
                      />
                      <label>Hold Event ID</label>
                      <Input
                        placeholder="Hold Event ID"
                        value={platform.adjustParams?.eventIds.hold || ""}
                        onChange={(value) =>
                          handleCreatePlatformChange(index, {
                            adjustParams: {
                              ...platform.adjustParams!,
                              eventIds: {
                                ...platform.adjustParams!.eventIds,
                                hold: value,
                              },
                            },
                          })
                        }
                        style={{ width: "100%" }}
                      />
                      <label>Sale Event ID</label>
                      <Input
                        placeholder="Sale Event ID"
                        value={platform.adjustParams?.eventIds.sale || ""}
                        onChange={(value) =>
                          handleCreatePlatformChange(index, {
                            adjustParams: {
                              ...platform.adjustParams!,
                              eventIds: {
                                ...platform.adjustParams!.eventIds,
                                sale: value,
                              },
                            },
                          })
                        }
                        style={{ width: "100%" }}
                      />
                      <label>Rejected Event ID</label>
                      <Input
                        placeholder="Rejected Event ID"
                        value={platform.adjustParams?.eventIds.rejected || ""}
                        onChange={(value) =>
                          handleCreatePlatformChange(index, {
                            adjustParams: {
                              ...platform.adjustParams!,
                              eventIds: {
                                ...platform.adjustParams!.eventIds,
                                rejected: value,
                              },
                            },
                          })
                        }
                        style={{ width: "100%" }}
                      />
                      <p className="section-header">Metrica</p>
                      <label>Metrica App ID</label>
                      <Input
                        placeholder="Metrica App ID"
                        value={platform.metricaParams?.appId?.toString() || ""}
                        onChange={(value) =>
                          handleCreatePlatformChange(index, {
                            metricaParams: {
                              ...platform.metricaParams!,
                              appId: parseInt(value) || 0,
                            },
                          })
                        }
                        style={{ width: "100%" }}
                      />
                      <label>Metrica Post API Key</label>
                      <Input
                        placeholder="Metrica Post API Key"
                        value={platform.metricaParams?.postApiKey || ""}
                        onChange={(value) =>
                          handleCreatePlatformChange(index, {
                            metricaParams: {
                              ...platform.metricaParams!,
                              postApiKey: value,
                            },
                          })
                        }
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                ))}
                <Button onClick={handleAddNewPlatform}>
                  Додати нову платформу
                </Button>
              </div>
            )}
          </div>
          <Modal
            open={isModalOpen}
            onClose={handleCancel}
            className="custom_modal_aprove"
          >
            <Modal.Body>Створити нову компанію?</Modal.Body>
            <Modal.Footer>
              <Button onClick={handleConfirm} appearance="primary">
                Підтвердити
              </Button>
              <Button onClick={handleCancel} appearance="subtle">
                Відхилити
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default PlatformsAccordion;


