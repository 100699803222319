import {IntegrationVersion } from "@bprotsyk/aso-core";

 export const emptyAppState = {
    id: 0, // або інше значення за замовчуванням
    name: "",
    bundle: "",
    sourceUrl: "",
    enabled: false,
    geos: "UA",
    geo: [],
    appmetricaApiKey: "",
    appmetricaAppId: 0,
    integrationVersion: IntegrationVersion.NONE, // або інше значення за замовчуванням
    keitaroData: false, // або null, якщо це дозволено
    offersStubParams: {}, // або null, якщо це дозволено
    bannerParams: null,
    domainParams: {
      name: "" // або інші обов'язкові поля, якщо є
    },
    directParams: null,
    externalParams: null,
    platforms: {
        "@": {
            enabled: true,
            params: null,
            keitaroData:true,
            newPlatform: true,
            geo: ["UZ","AZ","BY","MD","PL","CZ","RU","UA","KZ"]

        },
    },
    proxied: false,
    imageFormat: undefined, // або інше значення за замовчуванням
    file: null,
  };