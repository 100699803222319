import "./_stub-params.scss";
import { useEffect, useState, useRef } from "react";
import { Button, Input, SelectPicker, Tree } from "rsuite";
import Editor from "@monaco-editor/react";
import { IApp, IOffersStubParams } from "@bprotsyk/aso-core/lib/app/app";
import { IUpsertStubRequest, PanelService } from "network/panel/network";
import { IUpsertAppRequest } from "@bprotsyk/aso-core";
import { ReactComponent as PolicyIcon } from "../policy.svg";
import http from "network/panel/http";
import Uploader, { FileType } from "rsuite/esm/Uploader";
import FolderFillIcon from '@rsuite/icons/FolderFill';
import TrashIcon from '@rsuite/icons/Trash';
import AddOutlineIcon from '@rsuite/icons/AddOutline';



const API_URL = http.defaults.baseURL?.replace(/^https?:\/\//, '');

interface IProps {
  app: IApp | IUpsertAppRequest;
  onSave: (params: IUpsertStubRequest) => void;
  stubData: IAppStubParams | null;
}

export interface IAppStubParams {
  "list.json": Array<Record<string, any>>;
  "template.txt": Record<string, string>;
  policyIncludes: boolean;
  treeData: Record<string, any>[]
}

interface ImageItem {
  backgroundImage: File | null;
  overlayImage: File | null;
}

export const StubParams = ({ app, onSave, stubData }: IProps) => {
  const [offersPath, setOffersPath] = useState(
    app.offersStubParams?.offersPath || ""
  );
  const [imagesPath, setImagesPath] = useState(
    app.offersStubParams?.imagesPath || ""
  );
  const [appStubParams, setAppStubParams] = useState<IAppStubParams | null>();
  const [id, setIndex] = useState(""); //{{id}}
  const [gameName, setGameName] = useState( ""); //{{name}}
  const [bg, setBg] = useState(""); //{{bg}
  const [fg, setFg] = useState(""); //{{fg}}
  const [text, setText] = useState(""); //{{text}}
  const [jsonEdit, setJsonEdit] = useState<IAppStubParams["list.json"]>([]);
  const [editorContent, setEditorContent] = useState("[]");
  const [policyData, setPolicyData] = useState(false);
  const prevParams = useRef<IUpsertStubRequest | null>(null);
  const [treeData, setTreeData] = useState<Record<string, any>[]>([]);
  const [newFiles, setNewFiles] = useState<File[]>([]);

  useEffect(() => {
    if (stubData) {
   setAppStubParams(stubData)
    }
  }, [stubData]);

  useEffect(() => {
    if (appStubParams && appStubParams["list.json"]) {
      setJsonEdit(appStubParams["list.json"]);
    }
    if (appStubParams?.treeData) {
      setTreeData(appStubParams.treeData);
    }
  }, [appStubParams]);


  useEffect(() => {
    if (jsonEdit.length > 0) {
      setEditorContent(JSON.stringify(jsonEdit, null, 2));
    }
  }, [jsonEdit]);

  const handleEditorChange = (value: string | undefined) => {
    setEditorContent(value || "[]");
  };

  const getAppStub = async (appId: number) => {
    try {
      const response = await PanelService.getAppStub(appId, app.domainParams.name);
      const data: IAppStubParams = response.data;
      setAppStubParams(data);
       setTreeData(data.treeData);
      setPolicyData(data.policyIncludes);
    } catch (error) {
      console.error("Error fetching app stub:", error);
    }
  };

  useEffect(() => {
    if (app.id) {
      getAppStub(app.id);
    }
  }, [app.id]);


  const templateUpsert = 
    {
    idKey: id,
    gameNameKey: gameName,
    bgKey: bg,
    fgKey: fg,
    textKey: text,
    }
  

  const jsonEditData = () => {
    try {
      return JSON.parse(editorContent)
    } catch (error) {
      console.error(error)
      return []
    }
  }



  useEffect(() => {
    if (!templateUpsert || !editorContent) {
      return;
    }

    console.log(newFiles);

    const params: IUpsertStubRequest = {
      appId: app.id,
      name: app.domainParams.name,
      templateUpsert: templateUpsert,
      jsonEdit: jsonEditData(),
      offersStubParams: {
        offersPath: offersPath,
        imagesPath: imagesPath,
      },
     
      images: newFiles,
      treeData: treeData,
    };
    console.log('params', params, treeData);

    if (JSON.stringify(params) !== JSON.stringify(prevParams.current)) {
      prevParams.current = params;
      onSave(params);
    }
  }, [templateUpsert, editorContent, treeData, newFiles]);






  useEffect(() => {
    if (appStubParams?.["template.txt"]) {
      const template = appStubParams["template.txt"];

      for (const [key, value] of Object.entries(template)) {
        if (value === "{{id}}") setIndex(key);
        if (value === "{{name}}") setGameName(key);
        if (value === "{{bg}}") setBg(key);
        if (value === "{{fg}}") setFg(key);
        if (value === "{{text}}") setText(key);
      }
    }
  }, [appStubParams]);



  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, node: any) => {
    const files = event.target.files;
    if (files) {
      const newNodes = Array.from(files).map(file => {
        return {
          label: file.name,
          value: URL.createObjectURL(file),
          visible: true,
          refKey: `String_${URL.createObjectURL(file)}`,
          newFilePath: `/var/www/ai/data/static/apps/offers-native/${app.id}/images/${node}/${file.name}`,
        };
      });

      setTreeData(prevData => {
        const updatedData = [...prevData];
        const folderIndex = updatedData.findIndex(item => item.label === node);

        if (folderIndex !== -1) {
          updatedData[folderIndex].children.push(...newNodes);
        } else {
          updatedData.push({
            label: node,
            value: '',
            children: newNodes,
          });
        }

        return updatedData;
      });

      setNewFiles(prevFiles => [...prevFiles, ...Array.from(files)]);
    }
  };

  const handleFileMove = (fileLabel: string, newFolderLabel: string, fileValue: string) => {
    setTreeData(prevData => {
      const updatedData = prevData.map(folder => {
        if (folder.children) {
          folder.children = folder.children.filter((file: any) => file.label !== fileLabel);
        }
        return folder;
      });

      const newFolderIndex = updatedData.findIndex(folder => folder.label === newFolderLabel);
      if (newFolderIndex !== -1) {
        const movedFile = {
          label: fileLabel,
          value:fileValue,
          newFilePath: `/var/www/ai/data/static/apps/offers-native/${app.id}/images/${newFolderLabel}/${fileLabel}`,
        };
        updatedData[newFolderIndex].children.push(movedFile);
      }

      return updatedData;
    });
  };

  const createNewFolder = () => {
    setTreeData(prevData => {
      const newFolderIndex = prevData.length;
      const newFolder = {
        label: newFolderIndex.toString(),
        value: `https://cg-data-server.com/static/apps/offers-native/${app.id}/images/${newFolderIndex}/`,
        children: [],
      };
      return [...prevData, newFolder];
    });
  };

  return (
    <div className="stub-params-container">
      <p className="title">Налаштування нативного оферволлу</p>
      <div className="content_container">
        <div className="left-section">
          <div className="input-block">
            <label>Offers Path</label>
            <Input
              value={offersPath}
              onChange={setOffersPath}
              placeholder="Введіть шлях до оферів"
            />
          </div>
          <div className="input-block">
            <label>Images Path</label>
            <Input
              value={imagesPath}
              onChange={setImagesPath}
              placeholder="Введіть шлях до зображень"
            />
          </div>
          <div className="input-block">
            <p className="title">Шаблон</p>
            <div className="input-block">
              <label>Ключ ідентифікатору</label>
              <Input
                value={id}
                onChange={setIndex}
                placeholder="Введіть ключ айді"
              />
            </div>
            <div className="input-block">
              <label>Ключ гри</label>
              <Input
                value={gameName}
                onChange={setGameName}
                placeholder="Введіть ключ назви"
              />
            </div>
            <div className="input-block">
              <label>Ключ зображення фону</label>
              <Input
                value={bg}
                onChange={setBg}
                placeholder="Введіть ключ зображення фону"
              />
            </div>
            <div className="input-block">
              <label>Ключ логотипу</label>
              <Input
                value={fg}
                onChange={setFg}
                placeholder="Введіть ключ зображення"
              />
            </div>
            <div className="input-block">
              <label>Ключ тексту кнопки</label>
              <Input
                value={text}
                onChange={setText}
                placeholder="Введіть Текст кнопки"
              />
            </div>
          </div>
        </div>

        <div className="right-section">
          <p className="title">Json данні для заглушки</p>
          <Editor
            height="300px"
            defaultLanguage="json"
            value={editorContent}
            theme="vs-dark"
            options={{
              minimap: { enabled: false },
              fontSize: 14,
              formatOnPaste: true,
              formatOnType: true,
              scrollBeyondLastLine: true,
              wordWrap: "on",
              wrappingIndent: "indent",
              automaticLayout: true,
              readOnly: false,
              padding: { top: 15, bottom: 15 },
            }}
            onChange={handleEditorChange}
          />

<div className="policy-block">
            <Uploader
              className="policy-uploader"
              method="POST"
              action={`//${API_URL}apps/offer-stub/`}
              multiple={false}
              headers={{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              }}
              name="policy"
              data={{
                appId: app.id,
                name: app.domainParams.name
              }}
            >
              <div>
                <Button color="blue" appearance="ghost" className="policy-button">
                  Додати політику приватності
                </Button>
                
                <PolicyIcon 
                  className="policy-icon" 
                  fill={policyData ? "#34c3ff" : "#000"} 
                  onClick={() => {
                    window.open(`https://${app.domainParams.name}/policy`, "_blank");
                  }}
                />
              </div>
            </Uploader>
              </div>

          <div className="images-block">
            <div className="container">
            <p className="title">Картинки</p> 
            <button onClick={createNewFolder} className="add-button"><AddOutlineIcon className="add-icon" /></button>
            </div>
            <Tree
              className="custom-tree"
              data={treeData || []}
              draggable
              defaultExpandAll
              renderTreeNode={(node) => (
                <>
                  <div className="tree-node" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                    {!node.children && (
                      <div className="image-overlay">
                        <TrashIcon 
                          className="trash-icon"
                          onClick={() => {
                            setTreeData(prevData => {
                              return prevData.map(folder => {
                                if (folder.children) {
                                  folder.children = folder.children.filter((file:any) => file.label !== node.label);
                                }
                                return folder;
                              }).filter(folder => folder.children.length > 0 || folder.label !== node.label);
                            });
                          }}
                        />
                      </div>
                    )}
                    {node.children ? (
                      <>
                 <div  className="folder-conatainer">
                      <input type="file" onChange={(e) => handleFileChange(e, node.label)} />
                      </div>
                     
                        <FolderFillIcon className="tree-icon-folder" />
                      </>
                    ) : (
                      <img className="tree-image" src={String(node.value)} alt="icon" />
                    )}
                    <span className="tree-label">{node.label}</span>
                  </div>
                </>
              )}
              onDrop={({ createUpdateDataFunction, dragNode, dropNode }) => {
                if (dropNode.children) {
                  setTreeData(prevData => {
                    const updatedData = createUpdateDataFunction(prevData);

                    // Отримуємо ім'я файлу та нову папку
                    const fileLabel = dragNode.label;
                    const newFolderLabel = dropNode.label;

                    // Викликаємо handleFileMove для оновлення newFilePath
                    handleFileMove(fileLabel, newFolderLabel, dragNode.value);

                    return updatedData;
                  });
                } else {
                  console.log('Перетягування у файли заборонено');
                }
              }}
            />
          </div>
        </div>
      </div>
      
    </div>
  );
};
