import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactCountryFlag from "react-country-flag";
import { TOffer, TPartner } from 'types/offers-temporary';
import OffersListItem from 'ui/containers/lists/offers/list-item/offers-list-item';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import './_geo-group.scss';
import { createPortal } from 'react-dom';

interface GeoGroupProps {
  countryCode: string;
  offers: (TOffer & { hidden: boolean })[];
  partners: Record<string, TPartner>;
  sectionId: string;
  onRemoveOffer: (offerId: number) => void;
  onToggleOfferHidden: (offerId: number, hidden: boolean) => void;
  onReorderOffers: (offerId: number, index: number) => void;
  isExpanded: boolean;
  onExpand: (expanded: boolean) => void;
  highlightedOfferId?: number | null;
}

const GeoGroup: React.FC<GeoGroupProps> = ({
  countryCode,
  offers,
  partners,
  sectionId,
  onRemoveOffer,
  onToggleOfferHidden,
  onReorderOffers,
  isExpanded,
  onExpand,
  highlightedOfferId
}) => {
  const [isLocalExpanded, setIsLocalExpanded] = useState(isExpanded);
  const contentRef = useRef<HTMLDivElement>(null);
  const [removingOfferId, setRemovingOfferId] = useState<number | null>(null);
  const [contentHeight, setContentHeight] = useState<number | null>(null);

  useEffect(() => {
    setIsLocalExpanded(isExpanded);
  }, [isExpanded]);

  const updateHeight = useCallback(() => {
    if (contentRef.current && isLocalExpanded) {
      const content = contentRef.current;
      const wrapper = content.querySelector('.geo-group__content-wrapper');
      if (wrapper) {
        const height = wrapper.scrollHeight;
        content.style.height = `${height}px`;
        setContentHeight(height);
      }
    }
  }, [isLocalExpanded]);

  useEffect(() => {
    if (contentRef.current) {
      const content = contentRef.current;
      if (isLocalExpanded) {
        updateHeight();
      } else {
        content.style.height = '0';
        setContentHeight(0);
      }
    }
  }, [isLocalExpanded, offers.length, updateHeight]);

  const handleRemoveOffer = (offerId: number) => {
    setRemovingOfferId(offerId);
    const offerElement = contentRef.current?.querySelector(`[data-offer-id="${offerId}"]`);
    if (offerElement) {
      offerElement.classList.add('removing');
      // First update height to include the removing animation
      if (contentHeight !== null && contentRef.current) {
        contentRef.current.style.height = `${contentHeight}px`;
      }
      
      setTimeout(() => {
        onRemoveOffer(offerId);
        setRemovingOfferId(null);
        // After removal, update height again
        requestAnimationFrame(() => {
          if (contentRef.current) {
            const wrapper = contentRef.current.querySelector('.geo-group__content-wrapper');
            if (wrapper) {
              const newHeight = wrapper.scrollHeight;
              contentRef.current.style.height = `${newHeight}px`;
              setContentHeight(newHeight);
            }
          }
        });
      }, 200); // Match the removal animation duration
    } else {
      onRemoveOffer(offerId);
    }
  };

  const handleToggleExpand = () => {
    const newExpanded = !isLocalExpanded;
    setIsLocalExpanded(newExpanded);
    onExpand?.(newExpanded);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // Get the offer being dragged
    const draggedOffer = offers[sourceIndex];
    
    // Call onReorderOffers with the new index
    onReorderOffers(draggedOffer.id, destinationIndex);
  };

  // Sort offers to match the display order
  const sortedOffers = [...offers].sort((a, b) => {
    const indexA = offers.findIndex(o => o.id === a.id);
    const indexB = offers.findIndex(o => o.id === b.id);
    return indexA - indexB;
  });

  return (
    <div className="geo-group">
      <div 
        className="geo-group__header" 
        onClick={handleToggleExpand}
        data-expanded={isLocalExpanded}
      >
        <div className="geo-group__country">
          <ReactCountryFlag 
            style={{
              fontSize: '1rem',
              lineHeight: '1rem',
            }} 
            className="geo-group__flag" 
            countryCode={countryCode} 
          />
          <span className="geo-group__name">{countryCode}</span>
          <span className="geo-group__count">({offers.length})</span>
        </div>
        <span className={`geo-group__spoiler ${isLocalExpanded ? 'expanded' : ''}`}>▾</span>
      </div>
      
      <div 
        ref={contentRef}
        className={`geo-group__content ${isLocalExpanded ? 'expanded' : ''}`}
      >
        {isLocalExpanded && (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={`${sectionId}-${countryCode}`}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="geo-group__content-wrapper"
                >
                  {offers.map((offer, index) => (
                    <Draggable
                      key={offer.id}
                      draggableId={`${offer.id}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`${snapshot.isDragging ? 'dragging' : ''}`}
                            style={provided.draggableProps.style}
                            data-offer-id={offer.id}
                            onDragStart={(e) => {
                              e.dataTransfer.setData('text/plain', JSON.stringify({
                                id: offer.id,
                                geo: offer.geo
                              }));
                              e.dataTransfer.effectAllowed = 'move';
                            }}
                          >
                            {!snapshot.isDragging && (
                              <OffersListItem
                                key={offer.id}
                                offer={offer}
                                partner={partners[offer.partnerId]}
                                index={index}
                                onRemove={handleRemoveOffer}
                                onToggleHidden={onToggleOfferHidden}
                                isHidden={offer.hidden}
                              />
                            )}
                          </div>
                          {snapshot.isDragging && createPortal(
                            <div
                              className="dragging-portal"
                              style={provided.draggableProps.style}
                            >
                              <OffersListItem
                                key={offer.id}
                                offer={offer}
                                partner={partners[offer.partnerId]}
                                index={index}
                                onRemove={handleRemoveOffer}
                                onToggleHidden={onToggleOfferHidden}
                                isHidden={offer.hidden}
                              />
                            </div>,
                            document.body
                          )}
                        </>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
    </div>
  );
};

export default GeoGroup; 