import { IOffer } from "@bprotsyk/aso-core"

export enum GroupOffersBy {
    PARTNERS = "partners",
    COUNTRIES = "countries"
}

export interface GroupOffersByQuery {
    groupBy: GroupOffersBy;
    showHidden: boolean;
}

export interface ShowHiddenOffersQuery {
    showHidden: boolean;
}

export interface TOffer {
    id: number;
    name: string;
    partnerId: string;
    graphicFolder: string;
    geo: string;
    caption: string;
    hidden?: boolean;
    link?: string;
}

export interface TPartner {
    id: number;
    name: string;
    partnerId: string;
}

export interface IOfferState {
    offerId: number;
    hidden: boolean;
}

export interface IAppOffersSection {
    sectionId: number;
    offerStatesOrdered: { [key: string]: IOfferState[] };
}

export interface ISectionsList {
    id: number;
    content: IAppOffersSection[];
}

export interface IOffersAdminResponse {
    partners: TPartner[];
    byPartners: { [key: string]: TOffer[] };
    byCountries: { [key: string]: TOffer[] };
} 