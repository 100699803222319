import React from "react";
import { IApp, IUpsertAppRequest } from "@bprotsyk/aso-core";
import Editor from "@monaco-editor/react";
import "./_app-json.scss";

interface IProps {
  app?: IApp | IUpsertAppRequest;
  isEditable: boolean;
  updateApp: (app: IApp | IUpsertAppRequest) => void;
}

export const AppJSON: React.FC<IProps> = ({ app, isEditable, updateApp }) => {
  const handleEditorChange = (value: string | undefined) => {
    if (value) {
      try {
        const parsedValue = JSON.parse(value);
        updateApp(parsedValue);
      } catch (e) {
        console.error("Invalid JSON");
      }
    }
  };

  return (
    <div className="app-json">
      <Editor
        height="58.7vh"
        defaultLanguage="json"
        defaultValue={JSON.stringify(app, null, 2)}
        theme="vs-dark"
        options={{
          minimap: { enabled: false },
          fontSize: 14,
          formatOnPaste: true,
          formatOnType: true,
          scrollBeyondLastLine: true,
          wordWrap: "on",
          wrappingIndent: "indent",
          automaticLayout: true,
          readOnly: false,
          padding: { top: 15, bottom: 15 },
        }}
        onChange={handleEditorChange}
      />
    </div>
  );
};
