import axios from "axios";

const $AUTH_API = axios.create({
  baseURL: "https://cg-main-server.com/",
  // baseURL: "http://localhost:4000/",

  headers: {
    "Content-type": "application/x-www-form-urlencoded",
  },
});
export default $AUTH_API;