import {
  Button,
  Form,
  Message,
  Modal,
  useToaster,
  Stack,
  Nav,
  TagPicker,
  SelectPicker,
  Toggle,
  Input,
  Loader,
} from "rsuite";
import "./_upsert-app.scss";
import CloseIcon from "@rsuite/icons/Close";

import { useEffect, useState } from "react";
import React from "react";
import {
  EPlatform,
  IApp,
  IDomain,
  IntegrationVersion,
  IPlatformParams,
  IUpsertAppRequest,
} from "@bprotsyk/aso-core";
import { AppInterface } from "ui/components/app/interface/app-interface";
import { AppJSON } from "./json/app-json";
import { countryNames, getCountryLabel } from "../../../../constants/countries";
import PlatformsAccordion from "./platforms";
import { emptyAppState } from "constants/app-initial";
import { IUpsertStubRequest, PanelService } from "network/panel/network";
import { IAppStubParams, StubParams } from "../app-stub/stub-params";

interface IProps {
  onClose: () => void;
  app?: IApp;
  create?: boolean;
  selectedPlatform?: EPlatform | null;
  selectedIntegration?: string | null;
}

const NO_DOMAINS_OPTION = [
  {
    label: "Немає доступних доменів",
    value: "no_domains",
  },
];

interface INoDomainsOption {
  label: string;
  value: string;
}

const PASSWORD = "123456";

type TabKey = "general" | "settings" | IntegrationVersion.OFFER_STUB;

const placement = "topEnd";

// Extend IPlatformParams to include newPlatform
interface ExtendedPlatformParams extends IPlatformParams {
  newPlatform?: boolean;
}

export default function UpsertAppModal({
  onClose,
  app,
  create,
  selectedPlatform,
  selectedIntegration,
}: IProps) {
  const toaster = useToaster();
  const [activeTab, setActiveTab] = useState<TabKey>(
    (selectedIntegration as TabKey) || "general"
  );  const [appStub, setAppStub] = useState<IUpsertStubRequest | null>(null);
  const [appState, setAppState] = useState<IApp | IUpsertAppRequest>(
    app || emptyAppState
  );

  const [isCreating, setIsCreating] = useState(create || false);
  const [isSaving, setIsSaving] = useState(false);
  console.log(activeTab, selectedIntegration);


  useEffect(() => {
    if (app?.platforms === null) {
      setAppState({ ...app, platforms: {} });
    }
  }, [app]);

  const availableCountries = Object.keys(countryNames)
    .map((code) => ({
      label: getCountryLabel(code),
      value: code,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const [isEditingAllowed, setIsEditingAllowed] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [availableDomain, setAvailableDomain] = useState<
    IDomain[] | INoDomainsOption[]
  >(NO_DOMAINS_OPTION);
  const [selectedDomain, setSelectedDomain] = useState<string | null>("");
  const [stubData, setStubData] = useState<IAppStubParams | null>(null);

  const handleCountriesChange = (value: string[]) => {
    setAppState((prevState: IApp | IUpsertAppRequest) =>
      prevState
        ? ({
            ...prevState,
            geos: value.join(","),
          } as IApp | IUpsertAppRequest)
        : prevState
    );
  };

  useEffect(() => {
    const filter = `/?target=main&appid=false`;
    PanelService.getDomains(filter).then((res) => {
      if (res.length > 0) {
        setAvailableDomain(res);
      }
    });
  }, []);

  const domainOptions = availableDomain?.flatMap((domain) => {
    if ("name" in domain && "id" in domain) {
      return {
        label: domain.name,
        value: domain.id,
      };
    }
    return NO_DOMAINS_OPTION;
  });

  const cleanPlatformsData = (
    platforms: Record<string, ExtendedPlatformParams>
  ) => {
    const cleanedPlatforms: Record<string, IPlatformParams> = {};
    Object?.keys(platforms).forEach((key) => {
      const { newPlatform, ...rest } = platforms[key];
      cleanedPlatforms[key] = rest;
    });
    return cleanedPlatforms;
  };

  const handleSave = async () => {
    setIsSaving(true);
    const savePromise = new Promise(async (resolve, reject) => {
      try {
        const cleanedAppState = {
          ...appState,
          platforms: cleanPlatformsData(appState.platforms),
        };
        const appResponse = await PanelService.upsertApp(cleanedAppState);
        setAppState(appResponse.data);
        
        if(appStub){
          const stubResponse = await PanelService.upsertStub(appStub);
          setStubData(stubResponse.data);
        }
        resolve(appResponse.data);
      } catch (error) {
        reject(error);
      }
    });

    savePromise
      .then((data: any) => {
        toaster.push(message(`success`, `Успішно збережено додаток #${data.id}`), { placement });
      })
      .catch(() => {
        toaster.push(message("error", "Помилка при збереженні"), { placement });
      })
      .finally(() => {
        setIsSaving(false);
        setIsCreating(false);
      });
  };

  const handleVersionChange = (value: string | null) => {
    if (value) {
      setAppState(
        (prevState) =>
          ({
            ...prevState,
            integrationVersion: value,
          } as IApp)
      );
    }
  };

  const handlePasswordSubmit = () => {
    if (password === PASSWORD) {
      setIsEditingAllowed(true);
      setShowPasswordModal(false);
    } else {
      alert("Неправильний пароль");
    }
  };

  const handleEditAttempt = () => {
    if (!isEditingAllowed) {
      setShowPasswordModal(true);
    }
  };

  const message = (messageType: any, messageText: string) => {
    return (
      <Message key={new Date().getTime()} showIcon type={messageType} closable>
        <strong>{messageText}</strong>
      </Message>
    );
  };

  const handlePlatformDataChange = (
    updatedPlatforms: Record<string, IPlatformParams>
  ) => {
    setAppState(
      (prevState) =>
        ({
          ...prevState,
          platforms: updatedPlatforms,
        } as IApp)
    );
  };

  const handleDomainNameChange = (value: string) => {
    console.log(value);
    if (value !== NO_DOMAINS_OPTION[0].value) {
      setAppState(
        (prevState) =>
          ({
            ...prevState,
            domainParams: {
              ...prevState.domainParams,
              name: value,
            },
          } as IApp)
      );
    }
  };

  const handleIdChange = (value: string) => {
    const numericId = parseInt(value, 10);
    if (!isNaN(numericId)) {
      setAppState(
        (prevState) =>
          ({
            ...prevState,
            id: numericId,
          } as IUpsertAppRequest | IApp)
      );
    }
  };

  const handleBundleChange = (value: string) => {
    setAppState(
      (prevState) =>
        ({
          ...prevState,
          bundle: value,
        } as IUpsertAppRequest | IApp)
    );
  };

  const handleNameChange = (value: string) => {
    setAppState(
      (prevState) =>
        ({
          ...prevState,
          name: value,
        } as IUpsertAppRequest | IApp)
    );
  };

  const updateApp = (updatedApp: IApp | IUpsertAppRequest) => {
    setAppState(updatedApp);
  };

  const handleDomainChange = (value: string | null) => {
    setSelectedDomain(value);
    setAppState((prevState) => ({
      ...prevState,
      domainParams: {
        ...prevState.domainParams,
        name:
          domainOptions.find((option) => option?.value === value)?.label || "",
      },
    }));
  };

  const handleSaveStub = (params: IUpsertStubRequest) => {
     setAppState((prevState: IApp | IUpsertAppRequest) =>
      prevState
        ? ({
            ...prevState,
            offersStubParams: params.offersStubParams,
          } as IApp | IUpsertAppRequest)
        : prevState
    );
   setAppStub(params)
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "general":
        return (
          <div className="general-container">
            <div className="general-container-left">
              <p className="title">Загальне</p>
              {create && (
                <div className="id-container">
                  <label>ID</label>
                  <Input
                    value={appState.id === 0 ? "" : String(appState.id)} // Якщо 0, показуємо пусте поле
                    onChange={(value) => {
                      if (value === "") {
                        handleIdChange("0"); // Якщо поле пусте, встановлюємо 0
                      } else {
                        handleIdChange(value);
                      }
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              )}
              <div className="domain-name-container">
                <label>Назва домену</label>
                {appState.domainParams.name && !create && (
                  <Input
                    value={appState.domainParams?.name || ""}
                    onChange={handleDomainNameChange}
                    style={{ width: "100%" }}
                    disabled={!!appState.domainParams.name}
                  />
                )}
                {create && (
                  <SelectPicker
                    data={domainOptions}
                    value={selectedDomain}
                    onChange={handleDomainChange}
                    searchable
                    placeholder="Виберіть домен"
                    disabled={selectedDomain === NO_DOMAINS_OPTION[0].value}
                    style={{ width: "100%" }}
                  />
                )}
              </div>

              <div className="bundle-container">
                <label>Bundle</label>
                <Input
                  value={appState.bundle || ""}
                  onChange={handleBundleChange}
                  style={{ width: "100%" }}
                />
              </div>

              <div className="bundle-container">
                <label>Назва</label>
                <Input
                  value={appState.name || ""}
                  onChange={handleNameChange}
                  style={{ width: "100%" }}
                />
              </div>

              {appState && "trackingUrl" in appState && (
                <div className="tracking-url-container">
                  <label>Tracking URL</label>
                  <Input
                    value={appState.trackingUrl || ""}
                    readOnly
                    style={{ width: "100%" }}
                  />
                </div>
              )}
              <div className="integration-version-container">
                <label>Версія інтеграції</label>
                <SelectPicker
                  className="integration-version-dropdown"
                  data={integrationVersions}
                  value={appState?.integrationVersion}
                  onChange={handleVersionChange}
                  cleanable={false}
                  searchable={false}
                  style={{ width: "100%" }}
                />
              </div>
 
            </div>

            {appState && (
              <PlatformsAccordion
                platforms={appState.platforms}
                onPlatformDataChange={handlePlatformDataChange}
                selectedPlatform={selectedPlatform}
              />
            )}
          </div>
        );
      case "settings":
        return appState ? (
          <div onClick={handleEditAttempt}>
            <AppJSON
              app={appState}
              isEditable={isEditingAllowed}
              updateApp={updateApp}
            />
          </div>
        ) : null;

      case IntegrationVersion.OFFER_STUB:
        return <StubParams app={appState} onSave={handleSaveStub} stubData={stubData} />;
      default:
        return null;
    }
  };

  const integrationVersionLabels: {
    [key in keyof typeof IntegrationVersion]: string;
  } = {
    NONE: "Немає",
    BANNER: "Банер",
    POLICY: "Політика",
    OFFER_STUB: "Нативний Офферволл",
    DIRECT: "Прямий",
    WEB: "Веб",
    WEB_DIRECT: "Прямий веб",
    PWA: "PWA",
  };

  const integrationVersions = Object.entries(IntegrationVersion)
    .sort(([keyA], [keyB]) => {
      if (keyA === IntegrationVersion.BANNER) return -1;
      if (keyB === IntegrationVersion.BANNER) return 1;
      if (keyA === IntegrationVersion.OFFER_STUB) return -1;
      if (keyB === IntegrationVersion.OFFER_STUB) return 1;
      return 0;
    })
    .map(([key, value]) => ({
      label:
        integrationVersionLabels[key as keyof typeof IntegrationVersion] || key,
      value: value,
    }));

  return (
    <div className="upsert_app_modal">
      <Modal
        backdrop={"static"}
        size="sm"
        open={true}
        onClose={onClose}
        className="custom_upsert_modal"
      >
        {isSaving && (
          <div 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1050
            }}
          >
            <Loader size="lg" content="Збереження..." style={{ color: 'white' }} />
          </div>
        )}
        <Modal.Header>
          <div className="modal-header-content">
            <Modal.Title>
              {app && !create ? (
                <>
                  Редагування додатку
                  <div className="app-id">
                    <span>#{app.id}</span>
                  </div>
                </>
              ) : (
                "Створення додатку"
              )}
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="upsert-app-modal-container">
            <Nav
              appearance="subtle"
              activeKey={activeTab}
              onSelect={setActiveTab}
              className="modal-tabs"
            >
              <Nav.Item eventKey="general">Загальне</Nav.Item>
              <Nav.Item eventKey="settings">Налаштування</Nav.Item>

              {appState.integrationVersion == IntegrationVersion.OFFER_STUB && (
                <Nav.Item eventKey={IntegrationVersion.OFFER_STUB}>Нативний Офферволл</Nav.Item>
              )}
            </Nav>
            <div className="tab-container">{renderTabContent()}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="edit_btn_wrap_upsert">
            <Button onClick={onClose} appearance="subtle">
              Скасувати
            </Button>
            <Button color="green" appearance="primary" onClick={handleSave}>
              {"Зберегти"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        open={showPasswordModal}
        className="password-modal"
        onClose={() => setShowPasswordModal(false)}
        size="sm"
      >
        <Modal.Header>
          <Modal.Title>Введіть пароль</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input
            type="password"
            value={password}
            onChange={(value) => setPassword(value)}
            placeholder="Пароль"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handlePasswordSubmit} appearance="primary">
            Підтвердити
          </Button>
          <Button
            onClick={() => setShowPasswordModal(false)}
            appearance="subtle"
          >
            Скасувати
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
