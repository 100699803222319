import { InputNumber, Button, Panel, ButtonGroup, Badge } from "rsuite";
import Accordion from 'rsuite/Accordion';
import { useState } from "react";
import "./_app-info.scss";
import { PanelService } from "network/panel/network";
import { IApp } from "@bprotsyk/aso-core/lib/app/app";


interface Offer {
    id: string;
    name: string;
    graphicFolder: string;
    geo: string;
    caption: string;
    partner: string;
}

interface Section {
    offers: Offer[];
    platformGeos: string[];
    title: string;
}

interface Platforms {
    [key: string]: {
        sections: Section[];
        platformGeos: string[];
        enabled: boolean;
    };
}

interface OfferInfo {
    geosRoot: string;
    appid: number;
    sections: Section[];
    platforms?: Platforms;
}

export default function AppInfo() {
  const [appId, setAppId] = useState<number | null>(null);
  const [offers, setOffers] = useState<OfferInfo | null>(null);
  const [selectedGeos, setSelectedGeos] = useState<Record<string, string | null>>({});
  const [expandedPlatforms, setExpandedPlatforms] = useState<Record<string, boolean>>({});

  const handleSubmit = () => {
    if (appId) {
      PanelService.getAppInfo(appId).then((res) => {
        setOffers(res?.data);
        setSelectedGeos({});
        setExpandedPlatforms({});
      });
    }
  };

  const renderOffers = (offers: Offer[], platform: string) => {
    const selectedGeo = selectedGeos[platform];
    const filteredOffers = offers.filter(offer => !selectedGeo || offer.geo === selectedGeo);
    return filteredOffers.map((offer) => (
      <>
      {
        offer && (
        <div className="offer-container">  <Panel 
          key={offer.id} 
          header={offer.caption} 
          collapsible 
          bordered
          className="offer-panel"
          style={{
          backgroundImage: `url(https://cg-data-server.com/static/offers/images/${offer.graphicFolder}/bg.png)`,
          }}
        >
          <p className="offer-text">ID: {offer.id}</p>
          <p className="offer-text">Name: {offer.name}</p>
          <p className="offer-text">Geo: {offer.geo}</p>
          <p className="offer-text">Partner: {offer.partner}</p>
        </Panel>
        <img src={`https://cg-data-server.com/static/offers/images/${offer.graphicFolder}/logo.png`} alt=""  className="offer-logo"/>
        </div>
        )
      }
      </>
    ));
  };

  const renderGeoButtons = (geos: string[], platform: string) => (
    <ButtonGroup className="geo-buttons">
      {geos.map(geo => (
        <Button
          key={geo}
          active={selectedGeos[platform] === geo}
          onClick={() => setSelectedGeos(prev => ({
            ...prev,
            [platform]: prev[platform] === geo ? null : geo
          }))}
        >
          {geo}
        </Button>
      ))}
    </ButtonGroup>
  );

  const renderSections = (sections: Section[], platform: string) => (
    sections.map((section, index) => (
      <div key={index} className="section-container">
        {section.offers.length > 0 && (
          <div className="section-offers">
            {section.title && <h4>{section.title}</h4>}
            {renderOffers(section.offers, platform)}
          </div>
        )}
      </div>
    ))
  );

  const sortPlatforms = (platforms: Platforms) => {
    return Object.entries(platforms).sort(([a], [b]) => {
      if (a === '@') return -1;
      if (b === '@') return 1;
      return a.localeCompare(b);
    });
  };

  const handlePlatformExpand = (platform: string, expanded: boolean) => {
    setExpandedPlatforms(prev => ({
      ...prev,
      [platform]: expanded
    }));
  };

  return (
    <div className="app-info">
      <div className="content-wrapper">
        <div className="input-container">
          <InputNumber 
            className="app-id-input"
            size="lg"
            placeholder="Введіть App ID"
            min={0}
            value={appId}
            onChange={value => setAppId(value as number)}
          />
          <Button 
            appearance="primary" 
            size="lg"
            onClick={handleSubmit}
            disabled={!appId}
          >
            Пошук
          </Button>
        </div>
        
        {offers && (
          <div className="app-info-container">
            <Panel header={`App ID: ${offers.appid}`} bordered>
              <p className="root-geos">Root Geos: {offers.geosRoot}</p>
              
              {offers.platforms && (
                <div className="platforms-container">
                  {sortPlatforms(offers.platforms).map(([platform, data]) => (
                    <Panel 
                      key={platform} 
                      header={
                        <div className="platform-header">
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <span>Платформа: {platform.toUpperCase()}</span>
                            <Badge color={data.enabled ? "green" : "red"} content={data.enabled ? "Увімкнено" : "Вимкнено"} />
                          </div>
                          <span className="platform-geos">{data.platformGeos.join(', ')}</span>
                        </div>
                      }
                      collapsible
                      bordered
                      defaultExpanded={false}
                      className="platform-panel"
                    >
                      {renderGeoButtons(data.platformGeos, platform)}
                      {renderSections(data.sections, platform)}
                    </Panel>
                  ))}
                </div>
              )}
            </Panel>
          </div>
        )}
      </div>
    </div>
  );
}


