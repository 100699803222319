// ListApps.tsx
import React, { useEffect, useState } from "react";
import { Badge, Button, Input, InputGroup, Table } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import "./_apps.scss";
import { PanelService } from "network/panel/network";
import { EPlatform, IApp, IntegrationVersion } from "@bprotsyk/aso-core";
import UpsertAppModal from "ui/modals/apps/app-upsert/upsert-app";
import { AppListItem } from "./app-list-item";
import { emptyAppState } from "constants/app-initial";

const { Column, HeaderCell, Cell } = Table;

const Apps: React.FC = () => {
  const [apps, setApps] = useState<IApp[]>([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedApp, setSelectedApp] = useState<IApp | null>(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableHeight, setTableHeight] = useState(window.innerHeight - 180);
  const [selectedPlatform, setSelectedPlatform] = useState<EPlatform | null>(
    null
  );
  const [selectedVersionModal, setSelectedVersionModal] = useState<IntegrationVersion|null>(null);

  useEffect(() => {
    const handleResize = () => {
      setTableHeight(window.innerHeight - 180);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch apps on component mount
  useEffect(() => {
    fetchApps();
  }, []);

  // Fetch apps from the server
  const fetchApps = async () => {
    setLoading(true);
    try {
      const data = await PanelService.getApps();
      setApps(Object.values(data));
    } catch (error) {
      console.error("Error fetching apps:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle search input change
  const handleSearchChange = (value: string) => {
    setSearch(value);
  };

  const handleAppClick = (app: IApp) => {
    setSelectedApp(app);
  };

  const handleModalClose = () => {
    setSelectedApp(null);
  };

  const handleSelectedPlatform = (app: IApp, platform: string) => {
    setSelectedApp(app);
    setSelectedPlatform(platform as EPlatform);
  };

  const handleSelectVersionModal = (app: IApp) => {
   if(app.integrationVersion === IntegrationVersion.OFFER_STUB){
    setSelectedVersionModal(IntegrationVersion.OFFER_STUB);
    setSelectedApp(app);
   }

  };
  

  // Filter apps based on search input
  const filteredApps = apps.filter((app) => {
    const searchLower = search.toLowerCase();
    const appIdString = `#${app.id}`;
    const integrationType = app.integrationVersion || "";

    return (
      appIdString.includes(searchLower) ||
      integrationType.toLowerCase().includes(searchLower)
    );
  });

  const platformsKeys = [
    ...new Set(apps?.flatMap((app) => Object?.keys(app?.platforms || {}))),
  ];


  return (
    <div className="apps">
      {createModalOpen && (
        <UpsertAppModal
          onClose={() => setCreateModalOpen(false)}
          create={true}
        />
      )}
      <div className="header">
        <Button
          className="create_new_app_btn"
          appearance="primary"
          color="green"
          onClick={() => setCreateModalOpen(!createModalOpen)}
        >
          Створити новий додаток
        </Button>
        <InputGroup inside style={{ width: 300 }}>
          <Input
            placeholder="Search by App ID or Integration Type"
            value={search}
            onChange={handleSearchChange}
          />
          <InputGroup.Addon>
            <SearchIcon />
          </InputGroup.Addon>
        </InputGroup>
      </div>

      <Table
        data={filteredApps}
        loading={loading}
        bordered
        cellBordered
        virtualized
        height={tableHeight}
      >
        <Column flexGrow={1} align="center">
          <HeaderCell>App</HeaderCell>
          <Cell>
            {(rowData: IApp) => (
              <AppListItem app={rowData} onClick={handleAppClick} />
            )}
          </Cell>
        </Column>

        <Column flexGrow={1} align="left">
          <HeaderCell>Версія інтеграції</HeaderCell>
          <Cell>
            {(rowData: IApp) => (
              <span className="app-list-item-integration-version" onClick={() => handleSelectVersionModal(rowData)} style={rowData.integrationVersion === IntegrationVersion.OFFER_STUB ? {cursor: 'pointer'} : {}}>
                {rowData.integrationVersion}
                
              </span>
            )}
          </Cell>
        </Column>

        <Column flexGrow={1} align="left">
          <HeaderCell>Платформи</HeaderCell>
          <Cell>
            {(rowData: IApp) => (
              <div className="platforms_cell">
                {platformsKeys?.map((platform) => (
                  rowData?.platforms?.[platform] && (
                    <div key={platform} className="platform_badge">
                      <Badge
                        key={platform}
                        color={
                          rowData.platforms[platform].enabled ? "blue" : "orange"
                        }
                        onClick={() => handleSelectedPlatform(rowData, platform)}
                      >
                        {platform}
                      </Badge>
                    </div>
                  )
                ))}
              </div>
            )}
          </Cell>
        </Column>
      </Table>

      {selectedApp && (
        <UpsertAppModal
          onClose={handleModalClose}
          app={selectedApp}
          selectedPlatform={selectedPlatform}
        />
      )}
      {selectedVersionModal && selectedApp && (
       <UpsertAppModal
       onClose={handleModalClose}
       app={selectedApp}
       selectedIntegration={selectedVersionModal}
     />
    )}
    </div>
  );
};

export default Apps;
