import React from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import './_split-layout.scss';

interface SplitLayoutProps {
  leftPane: React.ReactNode;
  rightPane: React.ReactNode;
}

const SplitLayout: React.FC<SplitLayoutProps> = ({ leftPane, rightPane }) => {
  return (
    <PanelGroup direction="horizontal">
      <Panel defaultSize={50} minSize={30} className="left-pane-parent">
        <div className="left-pane">
          {leftPane}
        </div>
      </Panel>
      <PanelResizeHandle className="resize-handle" />
      <Panel defaultSize={50} minSize={30}>
        <div className="right-pane">
          {rightPane}
        </div>
      </Panel>
    </PanelGroup>
  );
};

export default SplitLayout; 