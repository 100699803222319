import React, { useState, useEffect, useRef } from 'react';
import { TOffer, TPartner } from 'types/offers-temporary';
import ListSection from './section/list-section';
import './_offers-list.scss';
import type { ISectionsList, IAppOffersSection } from '@bprotsyk/aso-core/lib/offers/list';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

interface OffersListProps {
  droppableId: string;
  sectionsList: ISectionsList;
  allOffers: Record<number, TOffer>;
  partners: Record<string, TPartner>;
  defaultExpandedSection?: number;
  onSectionExpand: (sectionId: number) => void;
  onSectionsListChange: (newSectionsList: ISectionsList) => void;
  draggedOffer: TOffer | null;
  onAddOffer: (offerId: number, sectionId: number, insertIndex?: number) => void;
  isReorderMode: boolean;
  lastExpandedSection: number;
  onExitReorderMode: () => void;
}

const OffersList: React.FC<OffersListProps> = ({ 
  droppableId, 
  sectionsList, 
  allOffers, 
  partners,
  defaultExpandedSection,
  onSectionExpand,
  onSectionsListChange,
  draggedOffer,
  onAddOffer,
  isReorderMode,
  lastExpandedSection,
  onExitReorderMode
}) => {
  const [expandedSection, setExpandedSection] = useState<number | null>(defaultExpandedSection || null);
  const sectionHeights = useRef<Record<number, number>>({});
  const sectionRefs = useRef<Record<number, HTMLDivElement>>({});

  useEffect(() => {
    setExpandedSection(defaultExpandedSection || null);
  }, [defaultExpandedSection]);

  // Add effect to handle reorder mode changes
  useEffect(() => {
    if (isReorderMode) {
      // When entering reorder mode, collapse any expanded section
      setExpandedSection(null);
    } else {
      // When exiting reorder mode, restore the last expanded section
      setExpandedSection(lastExpandedSection);
    }
  }, [isReorderMode, lastExpandedSection]);

  const handleSectionExpand = (sectionId: number) => {
    // Don't allow expansion in reorder mode
    if (isReorderMode) return;

    // If there's only one section, keep it expanded
    if (sectionsList.content.length === 1) return;
    
    // If clicking on a different section than currently expanded
    if (sectionId !== expandedSection) {
      setExpandedSection(sectionId);
      onSectionExpand(sectionId);
    } else {
      // If clicking the same section, collapse it
      setExpandedSection(null);
      onSectionExpand(-1);
    }
  };

  const handleRemoveOffer = (offerId: number) => {
    const newSectionsList = {
      ...sectionsList,
      content: sectionsList.content.map(section => ({
        ...section,
        offerStatesOrdered: Object.fromEntries(
          Object.entries(section.offerStatesOrdered).map(([key, states]) => [
            key,
            states.filter(state => state.offerId !== offerId)
          ])
        )
      }))
    };
    onSectionsListChange(newSectionsList);
  };

  const handleToggleOfferHidden = (offerId: number, hidden: boolean) => {
    const newSectionsList = {
      ...sectionsList,
      content: sectionsList.content.map(section => ({
        ...section,
        offerStatesOrdered: Object.fromEntries(
          Object.entries(section.offerStatesOrdered).map(([key, states]) => [
            key,
            states.map(state => 
              state.offerId === offerId 
                ? { ...state, hidden }
                : state
            )
          ])
        )
      }))
    };
    onSectionsListChange(newSectionsList);
  };

  const handleReorderOffers = (offerId: number, sectionId: number, newIndex: number) => {
    const newSectionsList = {
      ...sectionsList,
      content: sectionsList.content.map(section => {
        if (section.sectionId === sectionId) {
          const offer = allOffers[offerId];
          if (!offer) return section;
          
          const geo = offer.geo || 'other';
          const states = [...(section.offerStatesOrdered[geo] || [])];
          const currentIndex = states.findIndex(state => state.offerId === offerId);
          
          if (currentIndex === -1) return section;
          
          // Remove from current position and insert at new position
          const [state] = states.splice(currentIndex, 1);
          states.splice(newIndex, 0, state);
          
          return {
            ...section,
            offerStatesOrdered: {
              ...section.offerStatesOrdered,
              [geo]: states
            }
          };
        }
        return section;
      })
    };
    onSectionsListChange(newSectionsList);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    if (result.type === 'section') {
      // Handle section reordering
      const newContent = Array.from(sectionsList.content);
      const [removed] = newContent.splice(result.source.index, 1);
      newContent.splice(result.destination.index, 0, removed);

      onSectionsListChange({
        ...sectionsList,
        content: newContent
      });
      return;
    }

    // Extract section IDs and country codes
    const [sourceSectionId] = result.source.droppableId.split('-');
    const [destSectionId] = result.destination.droppableId.split('-');
    const offerId = parseInt(result.draggableId);
    const newIndex = result.destination.index;

    // If dragging within the same section
    if (sourceSectionId === destSectionId) {
      handleReorderOffers(
        offerId, 
        parseInt(sourceSectionId.replace('section-', '')), 
        newIndex
      );
    } else {
      // Moving to a different section
      onAddOffer(
        offerId, 
        parseInt(destSectionId.replace('section-', '')), 
        newIndex
      );
      
      // Remove from the source section
      handleRemoveOffer(offerId);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="sections-list" type="section">
        {(provided) => (
          <div 
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="offers-list"
          >
            {sectionsList.content.map((section, index) => {
              const sectionOffers = Object.entries(section.offerStatesOrdered).flatMap(([_, states]) =>
                states.map(state => {
                  const offer = allOffers[state.offerId];
                  return offer ? { ...offer, hidden: state.hidden } : undefined;
                })
              ).filter((offer): offer is TOffer & { hidden: boolean } => offer !== undefined);

              const isExpanded = expandedSection != null && expandedSection > 0 && section.sectionId === expandedSection;

              return (
                <Draggable 
                  key={section.sectionId} 
                  draggableId={`section-${section.sectionId}`} 
                  index={index}
                  isDragDisabled={!isReorderMode}
                  disableInteractiveElementBlocking={true}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={(el) => {
                        provided.innerRef(el);
                        if (el) sectionRefs.current[section.sectionId] = el;
                      }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`section-wrapper ${isExpanded ? 'expanded' : ''} ${draggedOffer ? 'dragging-offer' : ''}`}
                      style={{
                        ...provided.draggableProps.style,
                        ...(isExpanded ? {
                          flexGrow: 1,
                          height: 'auto',
                          display: 'flex',
                          flexDirection: 'column'
                        } : {})
                      }}
                    >
                      <ListSection
                        sectionId={section.sectionId}
                        offers={sectionOffers}
                        partners={partners}
                        onRemoveOffer={handleRemoveOffer}
                        onToggleHidden={handleToggleOfferHidden}
                        isExpanded={isExpanded}
                        onExpand={handleSectionExpand}
                        onAddOffer={onAddOffer}
                        onReorderOffers={handleReorderOffers}
                        draggedOffer={draggedOffer}
                        isReorderMode={isReorderMode}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default OffersList; 