export  const countryNames: Record<string, string> = {
  // Європа
  UA: "Україна",
  PL: "Польща",
  DE: "Німеччина",
  GB: "Великобританія",
  FR: "Франція",
  IT: "Італія",
  ES: "Іспанія",
  PT: "Португалія",
  CZ: "Чехія",
  SK: "Словаччина",
  HU: "Угорщина",
  RO: "Румунія",
  MD: "Молдова",
  BY: "Білорусь",
  LT: "Литва",
  LV: "Латвія",
  EE: "Естонія",
  BG: "Болгарія",
  HR: "Хорватія",
  SI: "Словенія",
  AT: "Австрія",
  CH: "Швейцарія",
  BE: "Бельгія",
  NL: "Нідерланди",
  DK: "Данія",
  SE: "Швеція",
  NO: "Норвегія",
  FI: "Фінляндія",
  IE: "Ірландія",
  GR: "Греція",
  IS: "Ісландія",
  MT: "Мальта",
  CY: "Кіпр",
  LU: "Люксембург",
  MC: "Монако",
  LI: "Ліхтенштейн",
  SM: "Сан-Марино",
  VA: "Ватикан",

  // Азія
  JP: "Японія",
  KR: "Південна Корея",
  CN: "Китай",
  IN: "Індія",
  ID: "Індонезія",
  MY: "Малайзія",
  SG: "Сінгапур",
  TH: "Таїланд",
  VN: "В'єтнам",
  PH: "Філіппіни",
  BD: "Бангладеш",
  PK: "Пакистан",
  LK: "Шрі-Ланка",
  NP: "Непал",
  MM: "М'янма",
  KH: "Камбоджа",
  LA: "Лаос",
  BN: "Бруней",
  TL: "Східний Тимор",
  MN: "Монголія",
  KZ: "Казахстан",
  UZ: "Узбекистан",
  AZ: "Азербайджан",
  AM: "Вірменія",
  GE: "Грузія",
  TR: "Туреччина",
  IR: "Іран",
  IQ: "Ірак",
  SA: "Саудівська Аравія",
  AE: "ОАЕ",
  IL: "Ізраїль",
  JO: "Йорданія",
  LB: "Ліван",
  SY: "Сирія",
  OM: "Оман",
  KW: "Кувейт",
  QA: "Катар",
  BH: "Бахрейн",
  YE: "Ємен",
  AF: "Афганістан",
  TM: "Туркменістан",
  TJ: "Таджикистан",
  KG: "Киргизстан",

  // Африка
  EG: "Єгипет",
  NG: "Нігерія",
  ZA: "Південна Африка",
  KE: "Кенія",
  GH: "Гана",
  DZ: "Алжир",
  MA: "Марокко",
  TN: "Туніс",
  LY: "Лівія",
  SD: "Судан",
  ET: "Ефіопія",
  UG: "Уганда",
  TZ: "Танзанія",
  CI: "Кот-д'Івуар",
  SN: "Сенегал",
  CM: "Камерун",
  ZM: "Замбія",
  ZW: "Зімбабве",
  AO: "Ангола",
  NA: "Намібія",
  BW: "Ботсвана",
  MZ: "Мозамбік",
  MG: "Мадагаскар",
  RW: "Руанда",
  BI: "Бурунді",
  MW: "Малаві",
  LS: "Лесото",
  SZ: "Есватіні",
  GA: "Габон",
  CG: "Конго",
  CD: "ДР Конго",
  GQ: "Екваторіальна Гвінея",
  ST: "Сан-Томе і Принсіпі",
  CV: "Кабо-Верде",
  GW: "Гвінея-Бісау",
  GM: "Гамбія",
  SL: "Сьєрра-Леоне",
  LR: "Ліберія",
  GN: "Гвінея",
  BF: "Буркіна-Фасо",
  ML: "Малі",
  NE: "Нігер",
  BJ: "Бенін",
  TG: "Того",
  MR: "Мавританія",
  EH: "Західна Сахара",
  SO: "Сомалі",
  DJ: "Джибуті",
  ER: "Еритрея",
  SC: "Сейшели",
  MU: "Маврикій",
  KM: "Коморські Острови",

  // Північна Америка
  US: "США",
  CA: "Канада",
  MX: "Мексика",
  GT: "Гватемала",
  HN: "Гондурас",
  SV: "Сальвадор",
  NI: "Нікарагуа",
  CR: "Коста-Рика",
  PA: "Панама",
  CU: "Куба",
  DO: "Домініканська Республіка",
  HT: "Гаїті",
  JM: "Ямайка",
  BS: "Багамські Острови",
  BZ: "Беліз",
  BB: "Барбадос",
  TT: "Тринідад і Тобаго",
  AG: "Антигуа і Барбуда",
  LC: "Сент-Люсія",
  VC: "Сент-Вінсент і Гренадини",
  GD: "Гренада",
  KN: "Сент-Кітс і Невіс",

  // Південна Америка
  BR: "Бразилія",
  AR: "Аргентина",
  CL: "Чилі",
  CO: "Колумбія",
  PE: "Перу",
  VE: "Венесуела",
  EC: "Еквадор",
  BO: "Болівія",
  PY: "Парагвай",
  UY: "Уругвай",
  GY: "Гаяна",
  SR: "Суринам",
  GF: "Французька Гвіана",

  // Океанія
  AU: "Австралія",
  NZ: "Нова Зеландія",
  FJ: "Фіджі",
  PG: "Папуа Нова Гвінея",
  SB: "Соломонові Острови",
  VU: "Вануату",
  WS: "Самоа",
  TO: "Тонга",
  TV: "Тувалу",
  KI: "Кірибаті",
  FM: "Мікронезія",
  MH: "Маршаллові Острови",
  PW: "Палау",
  NR: "Науру",

  // Антарктида
  AQ: "Антарктида",

  // Додана країна
  RU: "Росія",
};

export const getCountryLabel = (code: string): string => {
  return countryNames[code] ? `${countryNames[code]} (${code})` : code;
}; 