import React, { useState, useEffect } from "react";
import { PanelService } from "../../../network/panel/network";
import { ButtonToolbar, Checkbox, Dropdown, Radio, RadioGroup } from "rsuite";
import { GroupOffersBy, IOffersAdminResponse, TOffer, TPartner } from "types/offers-temporary";
import "./_offers.scss"
import OfferSmall from "ui/components/offers/offer-small";
import ReactCountryFlag from "react-country-flag";
import { OfferCrudModal } from "ui/modals/offer/offer-crud";

const PREF_OFFER_GROUP_BY = "pref_offer_group_by";

interface OffersState {
  groupPartnersByCountries: boolean;
  groupCountriesByPartners: boolean;
  showHidden: boolean;
}

const RadioLabel = ({ children }: { children: React.ReactNode }) => (
  <label style={{ padding: 7 }}>{children}</label>
);

const Offers: React.FC = () => {
  const [state, setState] = useState<OffersState>({
    groupPartnersByCountries: false,
    groupCountriesByPartners: false,
    showHidden: false,
  });

  const [groupBy, setGroupBy] = useState<GroupOffersBy>(
    (localStorage.getItem(PREF_OFFER_GROUP_BY) as GroupOffersBy) || GroupOffersBy.PARTNERS
  );
  const [offers, setOffers] = useState<IOffersAdminResponse | null>(null);
  const [partners, setPartners] = useState<TPartner[]>([]);
  const [offerToEdit, setOfferToEdit] = useState<TOffer | null>(null);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await PanelService.getAllOffers(groupBy, state.showHidden, true);
        setOffers(response);
        setPartners(response.partners);
      } catch (error) {
        console.error('Error fetching offers:', error);
      }
    };
    fetchOffers();
  }, [groupBy, state.showHidden]);

  const renderPartnerOffers = (e: [string, TOffer[]]) => {
    let key = e[0]
    let value = e[1]

    if (!value || value.length == 0) return

    let partner = partners.find((p: TPartner) => p.partnerId == key)

    let title = <span className="partners-title noselect">{partner?.name}</span>

    let content: JSX.Element;

    if (!state.groupPartnersByCountries) {
      content = <div className="offers-column">{
        value.map((offer: TOffer) => {
          return <OfferSmall onClick={setOfferToEdit} offer={offer} key={offer.id} showCountry={true} />
        })
      }
      </div>
    } else {
      let countries = new Map<string, TOffer[]>()
      value.forEach((offer: TOffer) => {
        let geoOffers: TOffer[] = []
        if (countries.has(offer.geo)) {
          geoOffers = countries.get(offer.geo) || []
        }
        geoOffers.push(offer)
        countries.set(offer.geo, geoOffers)
      })

      content = <div className="offers-column">{
        Array.from(countries.entries()).map(([geo, offers]) => {
          return (
            <div key={geo} className="partners-section-geo">
              <div className="partners-country-container">
                <span className="partners-country noselect">{geo}</span>
                <ReactCountryFlag style={{
                  fontSize: '2rem',
                  lineHeight: '2rem',
                }} className="partners-country-sign noselect" countryCode={geo.toString()} />
              </div>

              {offers.map((offer: TOffer) => {
                return <OfferSmall onClick={setOfferToEdit} offer={offer} key={offer.id} showCountry={false} />
              })}
            </div>
          )
        })
      }</div>
    }

    return (
      <div className="partners-section" key={key}>
        {title}
        {content}
      </div>
    )
  }

  return (
    <div className="offers">
      <RadioGroup 
        name="groupByGroup" 
        className="groupByGroup" 
        onChange={value => {
          localStorage.setItem(PREF_OFFER_GROUP_BY, value as string);
          setGroupBy(value as GroupOffersBy);
        }} 
        inline 
        appearance="picker" 
        value={groupBy}
      >
        <RadioLabel>Групувати за: </RadioLabel>
        <Radio value={GroupOffersBy.COUNTRIES}>Країнами</Radio>
        <Radio value={GroupOffersBy.PARTNERS}>Партнерками</Radio>
      </RadioGroup>
      {offers && groupBy === GroupOffersBy.PARTNERS && renderPartnerOffers([offers.partners[0]?.partnerId || '', offers.byPartners[offers.partners[0]?.partnerId || ''] || []])}
      {offers && offerToEdit && <OfferCrudModal offer={offerToEdit} offers={offers.byPartners[offerToEdit.partnerId] || []} partners={offers.partners} onClose={() => setOfferToEdit(null)} />}
    </div>
  )
};

export default Offers;
