import React from 'react';
import { Modal, Button, Form } from 'rsuite';
import './_add-section-to-list-modal.scss';

interface AddSectionToListModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
}

const AddSectionToListModal: React.FC<AddSectionToListModalProps> = ({
  open,
  onClose,
  onSubmit
}) => {
  const handleSubmit = () => {
    onSubmit({});
  };

  return (
    <Modal className="add-section-modal" open={open} onClose={onClose} size="sm">
      <Modal.Header>
        <Modal.Title>Додати нову секцію</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form fluid>
          {/* Add form fields here when needed */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="subtle">
          Скасувати
        </Button>
        <Button onClick={handleSubmit} appearance="primary">
          Додати
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSectionToListModal; 