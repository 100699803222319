import React, { useState, useRef, useEffect } from 'react';
import { TOffer, TPartner } from 'types/offers-temporary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons';

interface OffersListItemProps {
  offer: TOffer;
  partner: TPartner;
  index: number;
  onRemove: (offerId: number) => void;
  onToggleHidden: (offerId: number, hidden: boolean) => void;
  isHidden?: boolean;
}

const OffersListItem: React.FC<OffersListItemProps> = ({ 
  offer, 
  partner, 
  index,
  onRemove,
  onToggleHidden,
  isHidden = false
}) => {
  const [isHolding, setIsHolding] = useState(false);
  const [holdProgress, setHoldProgress] = useState(0);
  const [isRemoving, setIsRemoving] = useState(false);
  const holdTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const holdStartTimeRef = useRef<number>(0);
  const animationFrameRef = useRef<number | null>(null);

  const handleTrashMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsHolding(true);
    holdStartTimeRef.current = Date.now();
    
    holdTimeoutRef.current = setTimeout(() => {
      setIsRemoving(true);
      setTimeout(() => {
        onRemove(offer.id);
      }, 300); // Wait for fade animation
    }, 2000);

    // Animate progress with easing
    const animate = () => {
      if (!isHolding) return;

      const elapsed = Date.now() - holdStartTimeRef.current;
      const progress = Math.min(elapsed / 2000, 1);
      
      // Ease the progress for smoother animation
      const easedProgress = progress < 0.5
        ? 2 * progress * progress // Ease in
        : -1 + (4 - 2 * progress) * progress; // Ease out
      
      setHoldProgress(easedProgress);

      if (progress < 1) {
        animationFrameRef.current = requestAnimationFrame(animate);
      }
    };
    animationFrameRef.current = requestAnimationFrame(animate);
  };

  const handleTrashMouseUp = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (holdTimeoutRef.current) {
      clearTimeout(holdTimeoutRef.current);
    }
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    setIsHolding(false);
    setHoldProgress(0);
  };

  const handleTrashMouseLeave = (e: React.MouseEvent) => {
    handleTrashMouseUp(e);
  };

  useEffect(() => {
    return () => {
      if (holdTimeoutRef.current) {
        clearTimeout(holdTimeoutRef.current);
      }
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  return (
    <div
      className={`offers-list-item ${isRemoving ? 'removing' : ''}`}
      data-draggable="true"
    >
      <div className="offers-list-item__logo">
        {offer.graphicFolder && (
          <>
            <img
              src={`https://cg-data-server.com/static/offers/images/${offer.graphicFolder}/bg.png`}
              alt={offer.name}
              className="offers-list-item__thumbnail offers-list-item__thumbnail--bg"
            />
            <img
              src={`https://cg-data-server.com/static/offers/images/${offer.graphicFolder}/logo.png`}
              alt={offer.name}
              className="offers-list-item__thumbnail offers-list-item__thumbnail--fg"
            />
          </>
        )}
      </div>
      <div className="offers-list-item__details">
        <div className="offers-list-item__name">{offer.caption}</div>
        <div className="offers-list-item__partner">{partner?.name}</div>
      </div>
      <div className="offers-list-item__actions">
        <div 
          className={`offers-list-item__icon offers-list-item__icon--eye ${isHidden ? 'hidden' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            onToggleHidden(offer.id, !isHidden);
          }}
        >
          <FontAwesomeIcon icon={faEye} />
          <div className="eye-slash">
            <FontAwesomeIcon icon={faEyeSlash} />
          </div>
        </div>
        <div 
          className={`offers-list-item__icon offers-list-item__icon--trash ${isHolding ? 'holding' : ''}`}
          onMouseDown={handleTrashMouseDown}
          onMouseUp={handleTrashMouseUp}
          onMouseLeave={handleTrashMouseLeave}
        >
          <FontAwesomeIcon 
            icon={faTrash} 
            className="trash-icon"
          />
        </div>
      </div>
    </div>
  );
};

export default OffersListItem; 