import React from 'react';
import ReactCountryFlag from "react-country-flag";
import { TOffer } from 'types/offers-temporary';
import './_offer-drag-ghost.scss';

interface OfferDragGhostProps {
  offer: TOffer | null;
  dragImageRef: React.RefObject<HTMLDivElement>;
  partnerName?: string | number;
  countryCode?: string;
}

const OfferDragGhost: React.FC<OfferDragGhostProps> = ({ 
  offer, 
  dragImageRef, 
  partnerName, 
  countryCode 
}) => {
  return (
    <div
      ref={dragImageRef}
      className="drag-ghost__container"
      style={{
        position: 'fixed',
        top: '-1000px',
        left: '-1000px',
        display: 'none',
        opacity: '1',
        transition: 'none',
      }}
    >
      {offer && (
        <>
          <div className="drag-ghost__logo">
            <img 
              className="drag-ghost__thumbnail drag-ghost__thumbnail--bg" 
              src={`https://cg-data-server.com/static/offers/images/${offer.graphicFolder}/bg.png`}
            />
            <img 
              className="drag-ghost__thumbnail drag-ghost__thumbnail--fg" 
              src={`https://cg-data-server.com/static/offers/images/${offer.graphicFolder}/logo.png`}
            />
          </div>
          <div className="drag-ghost__content">
            <div className="drag-ghost__name">{offer.name}</div>
            {partnerName && (
              <div className="drag-ghost__partner">{partnerName.toString()}</div>
            )}
          </div>
          {countryCode && (
            <div className="drag-ghost__country">
              <ReactCountryFlag 
                countryCode={countryCode} 
                style={{
                  fontSize: '1rem',
                  lineHeight: '1rem',
                }}
              />
              <span className="drag-ghost__country-code">{countryCode}</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default OfferDragGhost; 