import http from "./http";
const login = async (username: string, password: string) => {
  try {
    const postData = `username=${username}&password=${password}`;
    const response = await http.post("/auth/login", postData);
    localStorage.setItem("token", response?.data.token);
    localStorage.setItem(
      "userRole",
      JSON.stringify(response?.data.accessScopes)
    );

    return response.data
  } catch (error) {
    console.log(error);
  }
};

export default { login }