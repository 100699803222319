import React, { useState, useEffect, useRef } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { TOffer, TPartner } from 'types/offers-temporary';
import ReactCountryFlag from "react-country-flag";
import GeoGroup from '../geo-group/geo-group';
import './_list-section.scss';
import { GeoOfferHandler } from 'ui/containers/lists/offers/geo-offer-handler/geo-offer-handler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronDown, faGripVertical } from '@fortawesome/free-solid-svg-icons';

interface ListSectionProps {
  sectionId: number;
  offers: (TOffer & { hidden: boolean })[];
  partners: Record<string, TPartner>;
  onRemoveOffer: (offerId: number) => void;
  onToggleHidden: (offerId: number, hidden: boolean) => void;
  isExpanded?: boolean;
  onExpand?: (sectionId: number) => void;
  onAddOffer?: (offerId: number, sectionId: number, insertIndex?: number) => void;
  onReorderOffers?: (offerId: number, sectionId: number, index: number) => void;
  draggedOffer?: TOffer | null;
  isReorderMode?: boolean;
}

const ListSection: React.FC<ListSectionProps> = ({
  sectionId,
  offers,
  partners,
  onRemoveOffer,
  onToggleHidden,
  isExpanded = false,
  onExpand = () => {},
  onAddOffer,
  onReorderOffers,
  draggedOffer,
  isReorderMode
}) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const dragTimeoutRef = useRef<number | null>(null);
  const sectionRef = useRef<HTMLDivElement>(null);
  const [showHandler, setShowHandler] = useState(false);
  const [handlerVisible, setHandlerVisible] = useState(false);
  const handlerTimeoutRef = useRef<number | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [newOfferId, setNewOfferId] = useState<number | null>(null);
  const [contentHeight, setContentHeight] = useState(0);

  // Track previous offers to detect new ones
  const prevOffersRef = useRef<typeof offers>([]);
  useEffect(() => {
    // Find newly added offer
    const newOffer = offers.find(offer => !prevOffersRef.current.some(prev => prev.id === offer.id));
    if (newOffer) {
      setNewOfferId(newOffer.id);
      // Clear highlight after animation
      setTimeout(() => setNewOfferId(null), 1000);
    }
    prevOffersRef.current = offers;
  }, [offers]);

  // Scroll to new offer
  useEffect(() => {
    if (newOfferId && contentRef.current) {
      const offerElement = contentRef.current.querySelector(`[data-offer-id="${newOfferId}"]`);
      if (offerElement) {
        // Get the current scroll position
        const currentScroll = contentRef.current.scrollTop;
        
        // Get element position
        const elementRect = offerElement.getBoundingClientRect();
        const containerRect = contentRef.current.getBoundingClientRect();
        
        // Determine scroll direction
        const scrollOffset = elementRect.top < containerRect.top ? -80 : 80;
        
        offerElement.scrollIntoView({ block: 'center' });
        // Add offset after scrolling
        contentRef.current.scrollTop += scrollOffset;
        
        // Add highlight class
        const highlightElement = offerElement.closest('.offer-item');
        if (highlightElement) {
          highlightElement.classList.add('highlight-new');
          setTimeout(() => {
            highlightElement.classList.remove('highlight-new');
          }, 1000);
        }
      }
    }
  }, [newOfferId]);

  // Group offers by geo
  const offersByGeo = offers.reduce((acc, offer) => {
    if (!acc[offer.geo]) {
      acc[offer.geo] = [];
    }
    acc[offer.geo].push(offer);
    return acc;
  }, {} as Record<string, TOffer[]>);

  const hasOffers = offers.length > 0;

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    if (!isExpanded) {
      onExpand(sectionId);
    }

    if (dragTimeoutRef.current) {
      window.clearTimeout(dragTimeoutRef.current);
      dragTimeoutRef.current = null;
    }

    setShowHandler(true);
    requestAnimationFrame(() => {
      setHandlerVisible(true);
    });
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    if (dragTimeoutRef.current) {
      window.clearTimeout(dragTimeoutRef.current);
      dragTimeoutRef.current = null;
    }
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();

    if (!sectionRef.current?.contains(e.relatedTarget as Node)) {
      setHandlerVisible(false);
      if (handlerTimeoutRef.current) {
        window.clearTimeout(handlerTimeoutRef.current);
      }
      handlerTimeoutRef.current = window.setTimeout(() => {
        setShowHandler(false);
      }, 150); // match transition duration
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    if (dragTimeoutRef.current) {
      window.clearTimeout(dragTimeoutRef.current);
      dragTimeoutRef.current = null;
    }

    // Remove handler and blur immediately
    setHandlerVisible(false);
    setShowHandler(false);
    setIsDraggingOver(false);

    if (!draggedOffer || !onAddOffer) return;

    try {
      const rawData = e.dataTransfer.getData('text/plain');
      const data = JSON.parse(rawData);
      
      if (!data || typeof data.id !== 'number') return;

      // If dropping on the GeoOfferHandler
      if (showHandler) {
        // If we have a handler index, use it directly
        if (typeof data.handlerIndex === 'number') {
          onAddOffer(data.id, sectionId, data.handlerIndex);
        } else {
          // If no handler index, add to the end of the section
          onAddOffer(data.id, sectionId);
        }
      } else {
        // If dropping directly on the section, add to the end
        onAddOffer(data.id, sectionId);
      }
      
      // Auto-expand the geo group for the dropped offer
      setExpandedGeos(prev => new Set([...prev, draggedOffer.geo]));
    } catch (err) {
      console.error('Error handling drop:', err);
    }
  };

  // Track which geo groups should be expanded
  const [expandedGeos, setExpandedGeos] = useState<Set<string>>(new Set());

  // Auto-expand geo group when offers change
  useEffect(() => {
    if (draggedOffer && offersByGeo[draggedOffer.geo]) {
      setExpandedGeos(prev => new Set([...prev, draggedOffer.geo]));
    }
  }, [offersByGeo, draggedOffer]);

  // Cleanup timeouts
  useEffect(() => {
    return () => {
      if (dragTimeoutRef.current) {
        window.clearTimeout(dragTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      if (handlerTimeoutRef.current) {
        window.clearTimeout(handlerTimeoutRef.current);
      }
    };
  }, []);

  const handleReorderOffers = (offerId: number, index: number) => {
    const droppedOffer = offers.find(o => o.id === offerId);
    if (!droppedOffer) return;

    // Get all offers with the same geo as the dropped offer
    const geoOffers = offers.filter(o => o.geo === droppedOffer.geo);
    
    // Find the current index within the geo group
    const currentGeoIndex = geoOffers.findIndex(o => o.id === offerId);
    
    // Calculate the target index within the geo group
    const targetGeoIndex = Math.min(index, geoOffers.length - 1);
    
    // Call onReorderOffers with the correct indices
    onReorderOffers?.(offerId, sectionId, targetGeoIndex);
  };

  const getSectionTitle = (id: number) => {
    switch (id) {
      case 1:
        return '🎰 ПЕРЕВІР СВОЮ ВДАЧУ! 🎰';
      case 2:
        return '🥇 СТАВ І ВИГРАВАЙ! 🥇';
      default:
        return `Section ${id}`;
    }
  };

  // Remove old animation effects
  useEffect(() => {
    if (contentRef.current) {
      const content = contentRef.current;
      content.style.display = isExpanded ? 'flex' : 'none';
    }
  }, [isExpanded]);

  // Add handler for toggling all geo groups
  const handleToggleAllGeoGroups = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent section collapse
    if (!isExpanded) return;

    const allGeos = Object.keys(offersByGeo);
    if (expandedGeos.size === allGeos.length) {
      // If all are expanded, collapse all
      setExpandedGeos(new Set());
    } else {
      // Otherwise expand all
      setExpandedGeos(new Set(allGeos));
    }
  };

  // Force show handler when expanded
  useEffect(() => {
    if (isExpanded && offers.length > 0) {
      setShowHandler(true);
      setHandlerVisible(true);
    } else {
      setShowHandler(false);
      setHandlerVisible(false);
    }
  }, [isExpanded, offers]);

  return (
    <div 
      ref={sectionRef}
      className={`list-section ${isReorderMode ? 'reorder-mode' : ''} ${isExpanded ? 'list-section--expanded' : ''}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div 
        className="list-section__header"
        onClick={() => !isExpanded && onExpand(sectionId)}
      >
        <h3 
          className={`list-section__title ${!hasOffers ? 'list-section__title--empty' : ''}`}
          data-expanded={isExpanded}
        >
          {getSectionTitle(sectionId)}
          <div className="section-icons">
            {isReorderMode && (
              <FontAwesomeIcon 
                icon={faGripVertical} 
                className={`section-drag-handle ${isReorderMode ? 'visible' : ''}`}
              />
            )}
            <FontAwesomeIcon 
              icon={isExpanded ? faBars : faChevronDown}
              className="icon"
              onClick={isExpanded ? handleToggleAllGeoGroups : undefined}
            />
          </div>
        </h3>
      </div>
      
      <div 
        ref={contentRef}
        className="list-section__content"
        style={{ display: isExpanded ? 'flex' : 'none' }}
      >
        {isExpanded && (
          <Droppable droppableId={`section-${sectionId}`}>
            {(provided, snapshot) => (
              <div 
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={snapshot.isDraggingOver ? 'dragging-over' : ''}
              >
                {Object.entries(offersByGeo)
                  .sort(([geoA], [geoB]) => geoA.localeCompare(geoB))
                  .map(([geo, geoOffers]) => (
                  <GeoGroup
                    key={`${sectionId}-${geo}`}
                    countryCode={geo}
                    offers={geoOffers.map(offer => ({ ...offer, hidden: offer.hidden || false }))}
                    partners={partners}
                    sectionId={`section-${sectionId}`}
                    onRemoveOffer={onRemoveOffer}
                    onToggleOfferHidden={onToggleHidden}
                    onReorderOffers={(offerId, index) => handleReorderOffers(offerId, index)}
                    isExpanded={expandedGeos.has(geo)}
                    onExpand={(expanded) => {
                      setExpandedGeos(prev => {
                        const next = new Set(prev);
                        if (expanded) {
                          next.add(geo);
                        } else {
                          next.delete(geo);
                        }
                        return next;
                      });
                    }}
                    highlightedOfferId={newOfferId}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
        
        {showHandler && draggedOffer && (
          <div className={`geo-handler-wrapper ${handlerVisible ? 'visible' : ''}`}>
            <GeoOfferHandler
              geo={draggedOffer.geo}
              offers={offersByGeo[draggedOffer.geo] || []}
              partners={partners}
              onReorderOffers={(offerId, index) => handleReorderOffers(offerId, index)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ListSection; 