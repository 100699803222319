import React, { useState, useRef, useEffect } from 'react';
import ReactCountryFlag from "react-country-flag";
import { TOffer, TPartner } from "types/offers-temporary";
import './_geo-offer-handler.scss';

interface GeoOfferHandlerProps {
  geo: string;
  offers: TOffer[];
  partners?: Record<string, TPartner>;
  onReorderOffers?: (offerId: number, index: number) => void;
}

export const GeoOfferHandler: React.FC<GeoOfferHandlerProps> = ({ 
  geo, 
  offers, 
  partners = {},
  onReorderOffers
}) => {
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number } | null>(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [insertIndex, setInsertIndex] = useState<number | null>(null);
  const listElement = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, offers.length);
  }, [offers.length]);

  const findInsertIndex = (mouseY: number): number => {
    const items = itemsRef.current.filter(Boolean) as HTMLDivElement[];
    
    // If no items, insert at beginning
    if (items.length === 0) return 0;

    // Find the item with the strongest spread effect
    let closestIndex = 0;
    let maxSpreadEffect = -1;

    items.forEach((item, index) => {
      const rect = item.getBoundingClientRect();
      const itemMiddle = rect.top + rect.height / 2;
      const distance = Math.abs(mouseY - itemMiddle);
      
      if (distance <= 80) { // Same maxDistance as in calculateSpread
        const spreadEffect = 1 - (distance / 80);
        if (spreadEffect > maxSpreadEffect) {
          maxSpreadEffect = spreadEffect;
          closestIndex = index;
        }
      }
    });

    // If mouse is above the closest item's middle, insert before it
    const closestItem = items[closestIndex];
    const closestMiddle = closestItem.getBoundingClientRect().top + closestItem.getBoundingClientRect().height / 2;
    
    if (mouseY < closestMiddle) {
      return closestIndex;
    } else {
      return closestIndex + 1;
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    if (!isDraggingOver) setIsDraggingOver(true);
    
    const newPosition = { x: e.clientX, y: e.clientY };
    setMousePosition(newPosition);
    
    // Find insert position
    const newInsertIndex = findInsertIndex(e.clientY);
    if (newInsertIndex !== insertIndex) {
      setInsertIndex(newInsertIndex);
      console.log('New insert index:', newInsertIndex);
    }
    
    // Update visual spread
    if (listElement.current) {
      const items = itemsRef.current;
      items.forEach((item, index) => {
        if (item) {
          const style = calculateSpread(item, newPosition, index);
          if (style.transform) {
            item.style.transform = style.transform;
            item.style.transition = style.transition || '';
          }
        }
      });
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    try {
      const data = JSON.parse(e.dataTransfer.getData('text/plain'));
      if (data && typeof data.id === 'number' && insertIndex !== null) {
        // Create new data transfer with the handler index
        const newData = new DataTransfer();
        newData.setData('text/plain', JSON.stringify({
          ...data,
          handlerIndex: insertIndex
        }));
        // Replace the original dataTransfer
        Object.defineProperty(e, 'dataTransfer', {
          value: newData
        });
        onReorderOffers?.(data.id, insertIndex);
      }
    } catch (err) {
      console.error('Error handling drop:', err);
    }
    
    setIsDraggingOver(false);
    setMousePosition(null);
    setInsertIndex(null);
    
    // Reset all items
    itemsRef.current.forEach(item => {
      if (item) {
        item.style.transform = '';
        item.style.transition = 'transform 0.15s ease';
      }
    });
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    if (!listElement.current?.contains(e.relatedTarget as Node)) {
      setIsDraggingOver(false);
      setMousePosition(null);
      setInsertIndex(null);
      
      // Reset all items
      itemsRef.current.forEach(item => {
        if (item) {
          item.style.transform = '';
          item.style.transition = 'transform 0.15s ease';
        }
      });
    }
  };

  const calculateSpread = (
    item: HTMLElement,
    mouse: { x: number; y: number },
    index: number
  ) => {
    const itemRect = item.getBoundingClientRect();
    const itemMiddle = itemRect.top + itemRect.height / 2;
    const distance = Math.abs(mouse.y - itemMiddle);
    const maxDistance = 80;
    const maxSpread = 40;
    
    if (distance > maxDistance) return {};
    
    const spread = (1 - distance / maxDistance) * maxSpread;
    const isAbove = mouse.y < itemMiddle;
    
    return {
      transform: `translateY(${isAbove ? spread : -spread}px)`,
      transition: 'transform 0.15s ease'
    };
  };

  if (offers.length > 0) {
    return (
      <div 
        className="geo-offer-handler"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div 
          ref={listElement}
          className={`offers-list ${isDraggingOver ? 'dragging-over' : ''}`}
        >
          {offers.map((offer, index) => (
            <div
              key={offer.id}
              ref={el => itemsRef.current[index] = el}
              className="offer-item"
              data-offer-id={offer.id}
              draggable={false}
              style={{ userSelect: 'none' }}
            >
              <div className="offer-logo">
                <img 
                  className="offer-thumbnail-bg" 
                  src={`https://cg-data-server.com/static/offers/images/${offer.graphicFolder}/bg.png`} 
                  alt=""
                  draggable={false}
                />
                <img 
                  className="offer-thumbnail-fg" 
                  src={`https://cg-data-server.com/static/offers/images/${offer.graphicFolder}/logo.png`} 
                  alt=""
                  draggable={false}
                />
              </div>
              <div className="offer-details">
                <div className="offer-name">{offer.caption}</div>
                {partners[offer.partnerId] && (
                  <div className="offer-partner">{partners[offer.partnerId].name}</div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="country-info">
          <ReactCountryFlag 
            className="country-flag" 
            countryCode={geo} 
            style={{
              fontSize: '24px',
              lineHeight: '24px'
            }}
          />
          <span className="country-code">{geo}</span>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="geo-offer-handler"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className="placeholder">
        <span className="plus-icon">+</span>
        <div className="country-info">
          <ReactCountryFlag 
            className="country-flag" 
            countryCode={geo} 
            style={{
              fontSize: '24px',
              lineHeight: '24px'
            }}
          />
          <span className="country-code">{geo}</span>
        </div>
      </div>
    </div>
  );
}; 