import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ButtonToolbar, Dropdown } from "rsuite";
import Navbar, { NavbarTab } from "../../components/navbar/Navbar";
import { GroupOffersBy, IOffersAdminResponse, TOffer } from "types/offers-temporary";
import { IOfferParams } from "ui/components/offers/params";
import "./_offer-small.scss"
import ReactCountryFlag from "react-country-flag";
// import Dashboard from "ui/components/";
// import FlashApps from '../pages/flash-apps/FlashApps';

export default (params: IOfferParams) => {
    let { offer, showCountry, onClick } = params

    let bg = `https://cg-data-server.com/static/offers/images/${offer.graphicFolder}/bg.png`
    let fg = `https://cg-data-server.com/static/offers/images/${offer.graphicFolder}/logo.png`

    return (
        <div className="offer-small" onClick={() => onClick(offer)}>
            <div className="offer-small-thumbnail noselect">
                <img className="offer-small-thumbnail-bg" src={bg} />
                <img className="offer-small-thumbnail-fg" src={fg} />
            </div>
            <div className="offer-small-center noselect">
                <span className="offer-small-center-title">{offer.caption}</span>
                <span className="offer-small-center-subtitle">{offer.name}</span>
            </div>
            <div className="offer-small-end">
                {
                    showCountry && <div className="offer-country-container">
                        <ReactCountryFlag style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2rem',
                        }} className="offer-country-sign noselect" countryCode={offer.geo} />
                        <span className="offer-country noselect">{offer.geo}</span>
                    </div>
                }
                <div className="offer-end-space" />
                <span className="offer-id noselect">#{offer.id}</span>
            </div>
        </div>
    )
};
