import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { GroupOffersBy, TOffer, TPartner } from 'types/offers-temporary';
import { Button } from 'rsuite';
import Particles from "react-tsparticles";
import { Container, Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";
import AllOffersList from './all/all-offers-list';
import OffersList from './offers/offers-list';
import AddSectionToListModal from './modals/add-section-to-list-modal';
import './_list.scss';
import SplitLayout from './split/split-layout';
import { PanelService } from '../../../network/panel/network';
import type { ISectionsList, IAppOffersSection } from '@bprotsyk/aso-core/lib/offers/list';

interface OfferListContainerProps {
  onSectionsListChange?: (newSectionsList: ISectionsList) => void;
}

const OfferListContainer: React.FC<OfferListContainerProps> = ({ onSectionsListChange }) => {
  const [selectedOffers, setSelectedOffers] = useState<TOffer[]>([]);
  const [partners, setPartners] = useState<Record<string, TPartner>>({});
  const [isAddSectionModalOpen, setIsAddSectionModalOpen] = useState(false);
  const [expandedSection, setExpandedSection] = useState<number>(1);
  const [draggedOffer, setDraggedOffer] = useState<TOffer | null>(null);
  const [isReorderMode, setIsReorderMode] = useState(false);
  const [lastExpandedSection, setLastExpandedSection] = useState<number>(1);
  const [sectionsList, setSectionsList] = useState<ISectionsList>({
    id: 1,
    content: [
      {
        sectionId: 1,
        offerStatesOrdered: {}
      },
      {
        sectionId: 2,
        offerStatesOrdered: {}
      }
    ]
  });

  // Log sections list changes
  useEffect(() => {
    console.log('Sections List Updated:', JSON.stringify(sectionsList, null, 2));
    console.log('Selected Offers:', selectedOffers);
  }, [sectionsList, selectedOffers]);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await PanelService.getAllOffers(GroupOffersBy.PARTNERS, false, true);
        setPartners(Object.fromEntries(response.partners.map(p => [p.partnerId, p])));
        
        // Get all offers from byPartners object
        const allOffers = Object.values(response.byPartners || {}).flat();
        setSelectedOffers(allOffers);
        console.log('Fetched offers:', allOffers);
      } catch (error) {
        console.error('Error fetching partners:', error);
      }
    };
    fetchPartners();
  }, []);

  const handleAddSection = () => {
    setIsAddSectionModalOpen(true);
  };

  const handleAddSectionSubmit = (sectionData: any) => {
    setSectionsList(prev => ({
      ...prev,
      content: [...prev.content, {
        sectionId: prev.content.length + 1,
        offerStatesOrdered: {}
      }]
    }));
    setIsAddSectionModalOpen(false);
  };

  const isOfferInAnySection = (offerId: number): boolean => {
    return sectionsList.content.some(section => 
      Object.values(section.offerStatesOrdered).some(states =>
        states.some(state => state.offerId === offerId)
      )
    );
  };

  const getOfferHiddenState = (offerId: number): boolean => {
    for (const section of sectionsList.content) {
      for (const states of Object.values(section.offerStatesOrdered)) {
        const state = states.find(state => state.offerId === offerId);
        if (state) {
          return state.hidden;
        }
      }
    }
    return false;
  };

  const cleanupSection = (section: IAppOffersSection): IAppOffersSection => ({
    ...section,
    offerStatesOrdered: Object.fromEntries(
      Object.entries(section.offerStatesOrdered).map(([key, states]) => [
        key,
        states.filter(state => 
          state.offerId != null && selectedOffers.some(offer => offer.id === state.offerId)
        )
      ])
    )
  });

  const setSectionsListClean = (newState: ISectionsList | ((prev: ISectionsList) => ISectionsList)) => {
    if (typeof newState === 'function') {
      setSectionsList(prev => {
        const result = newState(prev);
        return {
          ...result,
          content: result.content.map(cleanupSection)
        };
      });
    } else {
      setSectionsList({
        ...newState,
        content: newState.content.map(cleanupSection)
      });
    }
  };

  const handleSectionExpand = (sectionId: number) => {
    if (sectionId !== expandedSection) {
      setExpandedSection(sectionId);
      onSectionsListChange?.(sectionsList);
    }
  };

  const handleSectionsListChange = (newSectionsList: ISectionsList) => {
    setSectionsList(newSectionsList);
    onSectionsListChange?.(newSectionsList);
  };

  const handleDragStart = (offer: TOffer) => {
    if (!isReorderMode) {
      setDraggedOffer(offer);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    setDraggedOffer(null);
  };

  const handleAddOffer = (offerId: number, sectionId: number, insertIndex?: number) => {
    setSectionsList(prev => {
      return {
        ...prev,
        content: prev.content.map(section => {
          if (section.sectionId === sectionId) {
            const offer = selectedOffers.find(o => o.id === offerId);
            if (!offer) return section;
            
            const geo = offer.geo || 'other';
            const states = section.offerStatesOrdered[geo] || [];
            
            if (insertIndex !== undefined) {
              const newStates = [...states];
              newStates.splice(insertIndex, 0, { offerId, hidden: false });
              return {
                ...section,
                offerStatesOrdered: {
                  ...section.offerStatesOrdered,
                  [geo]: newStates
                }
              };
            } else {
              return {
                ...section,
                offerStatesOrdered: {
                  ...section.offerStatesOrdered,
                  [geo]: [...states, { offerId, hidden: false }]
                }
              };
            }
          }
          // Remove from other sections
          return {
            ...section,
            offerStatesOrdered: Object.fromEntries(
              Object.entries(section.offerStatesOrdered).map(([key, states]) => [
                key,
                states.filter(state => state.offerId !== offerId)
              ])
            )
          };
        })
      };
    });
  };

  const toggleReorderMode = () => {
    const newMode = !isReorderMode;
    if (draggedOffer) {
      setDraggedOffer(null);
    }
    
    if (newMode) {
      setLastExpandedSection(expandedSection);
      setExpandedSection(-1);
      setIsReorderMode(true);
    } else {
      // First expand the section
      handleSectionExpand(lastExpandedSection);
      // Then exit reorder mode in the next frame to allow animation
      requestAnimationFrame(() => {
        setIsReorderMode(false);
      });
    }
  };

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: Container | undefined) => {
    if (container) {
      console.log("Particles container loaded");
    }
  }, []);

  return (
    <div className="offers-container">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fullScreen: false,
          background: {
            color: {
              value: "#000000",
            },
          },
          fpsLimit: 60,
          interactivity: {
            events: {
              onHover: {
                enable: true,
                mode: ["trail", "repulse"],
              },
              resize: true,
            },
            modes: {
              trail: {
                delay: 0.05,
                pauseOnStop: true,
                quantity: 2,
                particles: {
                  size: {
                    value: { min: 2, max: 4 }
                  },
                  move: {
                    speed: 1.5,
                    outModes: {
                      default: "none"
                    }
                  },
                  opacity: {
                    value: 0.3,
                    animation: {
                      enable: true,
                      speed: 0.2,
                      minimumValue: 0,
                      sync: false,
                      destroy: "min"
                    }
                  }
                }
              },
              repulse: {
                distance: 100,
                duration: 0.2,
                factor: 10,
                speed: 0.3,
                maxSpeed: 1,
                easing: "ease-out-cubic"
              }
            },
          },
          particles: {
            color: {
              value: "#169de0",
            },
            links: {
              enable: false,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "none",
              },
              random: true,
              speed: 0.2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 100,
              limit: 200,
            },
            opacity: {
              value: 0.3,
              animation: {
                enable: true,
                speed: 0.2,
                minimumValue: 0,
                sync: false,
                destroy: "min"
              }
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 2, max: 4 }
            },
            effect: {
              type: "star",
              options: {
                star: {
                  blur: 3,
                  color: "#169de0",
                  frequency: 0.1,
                  opacity: 0.15,
                  size: 2
                }
              }
            }
          },
          detectRetina: true,
        }}
      />
      <div className="drag-drop-context">
        <DragDropContext 
          onDragEnd={handleDragEnd}
          enableDefaultSensors={true}
          dragHandleUsageInstructions=""
        >
          <SplitLayout
            leftPane={
              <div className="offers-drop-zone">
                <OffersList 
                  droppableId="offers-list" 
                  sectionsList={sectionsList}
                  allOffers={selectedOffers.reduce((acc, offer) => {
                    acc[offer.id] = offer;
                    return acc;
                  }, {} as Record<number, TOffer>)}
                  partners={partners}
                  defaultExpandedSection={expandedSection}
                  onSectionExpand={handleSectionExpand}
                  onSectionsListChange={handleSectionsListChange}
                  draggedOffer={draggedOffer}
                  onAddOffer={handleAddOffer}
                  isReorderMode={isReorderMode}
                  lastExpandedSection={lastExpandedSection}
                  onExitReorderMode={() => setIsReorderMode(false)}
                />
                <div className="list-buttons-container">
                  <Button 
                    appearance="ghost"
                    color={isReorderMode ? "green" : "yellow"}
                    style={{
                      fontWeight: isReorderMode ? 'bold' : 'normal'
                    }}
                    onClick={toggleReorderMode}
                  >
                    {isReorderMode ? 'Зберегти' : 'Змінити порядок'}
                  </Button>
                  <Button 
                    appearance="primary"
                    onClick={handleAddSection}
                    disabled={isReorderMode}
                  >
                    Додати секцію
                  </Button>
                </div>
                <AddSectionToListModal
                  open={isAddSectionModalOpen}
                  onClose={() => setIsAddSectionModalOpen(false)}
                  onSubmit={handleAddSectionSubmit}
                />
              </div>
            }
            rightPane={
              <AllOffersList 
                droppableId="all-offers-list" 
                sectionsList={sectionsList}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                getOfferHiddenState={getOfferHiddenState}
              />
            }
          />
        </DragDropContext>
      </div>
    </div>
  );
};

export default OfferListContainer; 