import React from "react";
import { IApp } from "@bprotsyk/aso-core";

interface IProps {
  app: IApp;
  onClick: (app: IApp) => void;
}

export const AppListItem: React.FC<IProps> = ({ app, onClick }) => {
  return (
    <div className="app-list-item-container">
      <span
        className="app-list-item"
        onClick={() => onClick(app)}
        style={{ cursor: "pointer" }}
      >
        <span className="app-list-item-id-marker">#</span>
        {app.id}
      </span>
    </div>
  );
};
